import React, {Component,useState, useRef, useLayoutEffect, useEffect} from 'react';
import voiceStyle from './voice.module.less'
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import {
    setTimerType,
    setTime,
    clearBroadKey,
    setAudioAction,
    setChatRepId,
    setStopChatRepId,
    clearChatRepId
} from '../../store/actions'

// import {Recorder} from 'recorder-core'
// import 'recorder-core/src/engine/pcm'
// import RecordApp from 'recorder-core/src/app-support/app'

import SpeakLoading from '../speakLoading/SpeakLoading'
import TestCon from '../test/test'
import { Carousel,Modal, ConfigProvider, Spin  } from 'antd';
import '../../assets/iconfont/iconfont.css'

import PageLoading from '../speakLoading/pageLoading'

import PositionList from '../positionList/positionList.jsx';
import Area from '../area/area.jsx';
import Screen from '../screen/screen.jsx'
import DirectAdmission from '../positionInfo/directAdmission.jsx';
import Outsourcing from '../positionInfo/outsourcing.jsx';
import DeliveryRecord from '../record/deliveryRecord'
import InterviewReport from '../record/interviewReport'
import SignPop from '../flow/signPop/signPop'
import ScanIdCard from '../scanIdCard/scanIdCard.jsx';

import voiceImg from '../../assets/img/home/voice.png'
import stopVoice from '../../assets/img/home/stopVoice.gif'
import voiceIng from '../../assets/img/home/voiceIng.gif'
import listen from '../../assets/img/home/listen.gif'
import logoImg from '../../assets/img/bgIndex.png'
import downImg from '../../assets/img/home/down.png'
import homeImg from '../../assets/img/home/backHome.png'
import voiceClick from '../../assets/click.mp3'
import btnClick from '../../assets/click1.wav'
import sendVoice from '../../assets/sendVoice.mp3'
import Alipay from '../../assets/img/home/Alipay.png'
import identity from '../../assets/img/home/identity.png'
import {PositionApi} from "../../services/positionApi.js";
import {ChatApi} from '../../services/chatApi.js';
import {AudioApi} from '../../services/audioApi.js';
import {SgAuthApi} from '../../services/sgAuthApi.js';
import {useLocation} from "react-router-dom";
// import WebSocket from 'websocket';

let positionAi = new PositionApi();

const chatApi = new ChatApi();
const audioApi = new AudioApi();


let sgAuthApi = new SgAuthApi();

const audioVoiceClick = new Audio(voiceClick);

const audioSendVoice = new Audio(sendVoice);

class Vioce extends Component {
    newest = React.createRef();
    robotCon= React.createRef();
    chatListH= React.createRef();
    deliveryRecordRef= React.createRef();

    constructor(props) {
        super();
        //react定义数据
        this.state = {
            showVoice: true,
            logo:'',
            logoTitle:'',
            ws: null,
            temp: null,
            loading: true,
            content: "",

            chatType: 1,      // 1:找工作、政策聊天 ， 2：心理咨询

            chatList: [],
            positionChatList: [],       // 缓存找工作、政策聊天记录
            psyConChatList: [],         //心理咨询聊天记录
            newList:[],
            dialogVisible:false,
            mainDialogVisible: false,
            mainUrl: '',
            logoShow:true,		//logo展示
            indCode: props.indCode,
            showList:0,     //1职位，2企业,3简历，4投递记录
            webGlId:props.webGlId,
            key: props.difyKey,
            sn: props.sn,

            strIndex:null,
            strTxt:'',
            timeJudge:null,
            overTime:null,
            audioFlag:false,		//判断是否录音，true：停止，false：开始
            messageSend:false,
            voiceFlag:true,		//判断是否收到回复，true：已回复，false：还未回复 （已经废弃）
            stopFlag:false,		//判断是否可以停止虚拟人对话 (已废弃)
            positionId:null,
            positionType:null,
            stopRobToBegin:false,  //停止机器人去说
            loadingBtnFlag: false,
            btnShow: false,
            beginAudio:false,      //开始录音
            animationDuration:'1s',
            showApplyWay:false,		//判断是否展示报名方式
            showTip: 1 ,// 展示顶部招呼语， 1：展示 0：不展示
            audioCtx:null,
            workGreeting:'',		//工作招呼语
            exampleList:[],			//例子数组
            policyGreeting:'',		//政策招呼语
            btnNumShow:null,        //判断点击工作个政策按钮
            listShow: true,         // true：职位列表  false：职位详情
            poisitinInfoData: {},
            isPlaying: false,       //判断是否完成播放
            modalBodyStyle:{
                height:'80vh'
            },
            userInfoData: {},
            backTo:null,    //1从投递记录跳转
            idCardInfoData: {},
            num: 1,
            scanPop: false,
            scanStatus: null,
            mobile:'',
            scriptSpeech:false,     //剧本

            audioStatus: 0,  // 0: 未收音，  1：开始收音  2：思考中（停止收音） 3：暂停收音
            userSpeaking: false,
            aiThink: false,

            backTime: 0,
            timerType:'',
            clickXBack:false,
            countToStopVoice:60,
            clickPolicy:false,      //判断是否点击政策
            btnWork:false,          //没有历史记录展示按钮
            stopVoice:stopVoice,
            voiceIng:voiceIng,
            listen:listen,
            downImgFlag:false,      //下滑按钮出现

            digReady: false,
            refreshCount:0,         //刷新职位列表
            chatObj: null,            //职位列表参数

            tempFlag: false,

            isFirstConnect: true,

            chatRepId: "",  //用于做打断标记

            infraredStatus: 0,  //红外状态，1：是有人， 0：是无人
            toWork:null,        //是否点击找工作
            machineNum:0,       //点击获取设备号次数
            showRecord:false,
            psyConKey: "",      //心理咨询dify key

            interviewKey: "",

            showRecordInfo: false,
            reportData: {},
        }
        this.chat_id = "";
        this.chat_index = 0;
        this.chat_temp_index = 0;
        this.chat_text_temp_index = 0;
        this.queue=[];
        this.timer = null;
        this.chatTexTtimer = null;
        this.playStatus = 0;

        this.backTimer = null;
        this.backTimeType ="";
        this.backTimeLimit = 0;
        this.backTimeConfig = [
            {
                type: "home",
                time: 59
            },
            {
                type: "positionList",
                time: 60
            },
            {
                type: "Area",
                time: 5000
            },
            {
                type: "Screen",
                time: 5000
            },
            {
                type: "positionInfo",
                time: 60
            },
            {
                type: "deliveryRecord",
                time: 60
            },
            {
                type: "verify",
                time: 15
            },
            {
                type: "signResult",
                time: 15
            },
            {
                type: "continueVerify",
                time: 60
            },
            {
                type: "scanIdCard",
                time: 60
            },
        ]

        //audio相关
        this.asrCloseTimer = null;
        this.asrCloseTime = 60;

        this.asrSendTimer = null;
        this.asrSendTime = 2000;

        this.websocketTimer = null;


        this.chatListRef= React.createRef();
        this.chatListScroll = this.chatListScroll.bind(this);

        this.broadKeys = [
            { key: 'positionSearch', value: '4632aabe33f0493faf8a903f8deafb8b',openAudio: true},
            { key: 'policy', value: '4aae5e9da2a24e3fb2a7319c3c81e3ff',openAudio: true},
            { key: 'positionDetail', value: '30c560d6565c4d3096b7a6ea313fbd19',openAudio: true},
            { key: 'verify', value: '6a1c7f0898e24af087bc730b3cf80724' ,openAudio: false},
            { key: 'succeed', value: '96d73ef31cba41a78f90c8fdba6184e0',openAudio: false},
            { key: 'defeat', value: '18b6b6e3bd2b44668bc2fb47a5c90022',openAudio: false},
            { key: 'continueVerify', value: '275ad357b86b431493d93604ea6d083b',openAudio: false},
            { key: 'scanIdCard', value: 'b660cda13fef4ef7bdff63e125e9fcad',openAudio: false},
            { key: 'swipeID', value: '275ad357b86b431493d93604ea6d083b',openAudio: false},
            { key: 'swipeIDDefeat', value: 'ba05d7024c0e410b9efe823c1efa53b8',openAudio: false},
            { key: 'psyCon', value: 'bd38dfb74c4c4fedaf7efc87ca522a1c',openAudio: false},
            { key: 'hello', value: '',openAudio: true}
        ]

        this.addTimer=null

        //扫脸点击判断
        this.faceBtnTimer=null
        //刷身份证点击判断
        this.cardBtnTimer=null

        this.areaData = {
            province: {},
            city: {},
            county: {}
        }
        this.selectScreenData = {
            workingLife: '',    // 工作性质
            education: '',    // 学历要求
            salary: '',    // 薪资要求
        }

        this.typeList = 1 // 1 全职 2 兼职/零工 3 实习 4 急聘
        this.screenAreaParams = {}
    }

    showVoiceCom(){

        this.setState({
            showVoice: true
        },() => {

            this.moveCenter();

            if(this.deliveryRecordRef.current){
                this.deliveryRecordRef.current.reloadData()
            }

            //创建websocket连接并增加心跳
            this.connectWsExc(() => {
                setTimeout(() => {
                    this.heartCheck()
                },2000)
            })
        })



    }

    componentDidMount(){

        // console.log(navigator.userAgent.indexOf('AlipayClient'),'oplp')
        // alert(this.state.key);

        //创建websocket连接并增加心跳
        this.connectWsExc(() => {
            setTimeout(() => {
                this.heartCheck()
            },2000)
        })

        this.init()

        //监听聊天记录列表滚动条
        this.chatListH.current.addEventListener('scroll', this.chatListScroll);

        //监听playStatus
        window.addEventListener('PlayStats', this.handlePlayChange);

        //数字人加载完成
        window.addEventListener('SceneReady', this.handleSceneReady);

        window.addEventListener('returnNumericPerson', this.closeMain);

        //监听小程序回传的语音
        // window.addEventListener('audio', this.handleAudio);
        // window.addEventListener('audiotest', this.handleAudioTest);

        //监听小程序回传的语音
        window.addEventListener('faceResult', this.handleFaceResult);
        window.addEventListener('faceResult2', this.handleFaceResult2);

        //监听小程序回传的语音
        window.addEventListener('idCardCheck', this.handleIdCardCheck);

        //监听页面点击
        window.addEventListener('refreshTime', this.refreshTime);

        //红外监听
        // window.addEventListener('infrared', this.infraredCheck);
        let logo=localStorage.getItem('IotLogo')
        let title=localStorage.getItem('IotTitle')
        this.setState({
            logo,
            logoTitle:title,
            // loading:true
        })

        // 获取心理咨询dify key
        let psyConKeyStr = localStorage.getItem('PSY_CON_KEY');

        this.setState({
            psyConKey: psyConKeyStr
        })
    }

    heartCheck(){
        this.websocketTimer = setInterval(() => {
            if(this.state.audioStatus===1){
                if (this.state.ws && this.state.ws.readyState === WebSocket.OPEN) {
                    this.state.ws.send("ping");
                }else{
                    this.connectWsExc();
                }
            }


        }, 3000);
    }

    clearHeartCheck(){
        clearInterval(this.websocketTimer);
        this.websocketTimer = null;
    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.chatListH.current&&!this.state.downImgFlag){
        //     let height=this.chatListH.current.scrollHeight
        //     if(height>400){
        //         // if(this.chatListH.current.scrollTop===height-400){
        //         //     this.setState({
        //         //         downImgFlag:true
        //         //     })
        //         // }
        //     }
        // }
        if (this.robotCon.current&&this.state.clickPolicy){
            this.robotCon.current.style.maxHeight='120px'
        }
        if (this.robotCon.current&&!this.state.clickPolicy){
            this.robotCon.current.style.maxHeight='60px'
        }

        //返回时间戳
        if(this.props.timerType !== prevProps.timerType){
            if(this.props.timerType === "" && this.backTimeType !== ""){
                this.clearBackTimer();
            }

            if (this.props.timerType &&this.backTimeType === "") {
                this.createBackTimer(this.props.timerType);
            }else if((this.props.timerType && this.backTimeType !== "" && this.backTimeType !== this.props.timerType)||( prevProps.timerType !== this.props.timerType)){

                this.clearBackTimer();
                this.createBackTimer(this.props.timerType?this.props.timerType:prevProps.timerType);
            }
        }

        //监听播报key的变化，如有变化播报相应key对应的播报
        if(this.props.broadKey && this.props.broadKey !== prevProps.broadKey ){
            this.aiBroadcastByKey(this.props.broadKey)
            this.props.clearBroadKey();
        }


        //首页情况下，判断有没有聊天，有聊天则数字人往左移动，没有就回到中间
        if(window.Player && window.Player.current&& window.Player.current.avatar){
            if(!this.state.dialogVisible){
                if(this.state.chatList.length>0 ){
                    this.moveLeft();
                }else{
                    this.moveCenter();
                }
            }else {
                this.moveReducePeople();
            }
        }

        //打断相关
        if(this.props.chatRepId !== prevProps.chatRepId){

            console.log("prop chatRepId----------------:" + this.props.chatRepId );

            this.setState({
                chatRepId: this.props.chatRepId
            });
        }



        //录音和数字人播报相关
        // console.log(this.props.audioAction,prevProps.audioAction );
        if(this.props.audioAction !== prevProps.audioAction && this.props.audioAction !== ""){
            if(this.props.audioAction === "startAudio"){ //开始收音
                if(this.state.audioStatus === 0){ //只有在静止状态下才能开始收音

                    this.stopBroadcast();

                    this.setState({
                        content:'',
                        strTxt:'',
                        audioStatus: 2,
                        userSpeaking: false
                    },() => {

                        this.createAsrCloseListener()
                        this.setAudioStatus(1,() => {
                            this.startAudio();
                        });
                    })


                }

                this.props.setAudioAction("");
            }

            if(this.props.audioAction === "reStartAudio"){ //开始收音
                if(this.state.audioStatus !== 1){ //只有在静止状态下才能开始收音

                    this.queue = []
                    this.stopBroadcast();
                    this.props.setStopChatRepId();

                    this.createAsrCloseListener(() =>{
                    })
                    this.setAudioStatus(1,() => {
                        this.startAudio();
                    });
                }

                this.props.setAudioAction("");
            }

            if(this.props.audioAction === "closeAudio" ){ //开始收音
                if(this.state.audioStatus === 1){ // 开始收音但未收到asr文本 会有60秒强制提交（如文本
                    this.setAudioStatus(0,() => {
                        this.closeAsrWebSocket(() => {
                            this.setAudioStatus(0,() => {
                                this.closeAudio();
                            });
                        })
                    });
                }
                this.props.setAudioAction("");
            }

            if(this.props.audioAction === "startBroadcast" ){ //开始收音

                if(this.state.audioStatus !== 3){
                    this.setAudioStatus(3,null);
                }
                this.props.setAudioAction("");
            }

            if(this.props.audioAction === "endBroadcast" ){ //开始收音

                if(this.state.showList < 4){
                    window.PlayStats.status = {
                        playCode: "",
                        status: 0, // 0 ：未播报， 1：已开始播报，2：剧本
                        isLast: false
                    };
                    this.createAsrCloseListener();
                    this.stopBroadcast();
                    setTimeout(() => {
                        this.setAudioStatus(1);
                        this.startAudio();
                    },800)



                }else{
                    this.setState({
                        audioStatus: 0,
                        animationDuration:'1s'
                    })
                }
                this.props.setAudioAction("");
            }

            if(this.props.audioAction === "stopBroadcast" ){ // 停止播报并开始收音

                this.stopBroadcast();
                this.props.setStopChatRepId();

                var arr = this.state.dialogVisible? this.state.newList : this.state.chatList;
                var lastItem = arr[arr.length-1];

                if(lastItem && lastItem.content === ""){

                    arr.pop();
                    if(this.state.dialogVisible){
                        this.setState({
                            newList: arr
                        })
                    }else{
                        this.setState({
                            chatList: arr
                        })
                    }
                }

                this.setState({
                    animationDuration:'1s'
                })
                this.createAsrCloseListener();
                this.setAudioStatus(0,() => {
                    // this.startAudio();
                });

                this.props.setAudioAction("");
            }

            if(this.props.audioAction === "resetStatus" ){ //回到初始状态

                this.setState({
                    newList: [],
                    chatList: [],
                    positionChatList: [],
                    psyConChatList: [],
                    downImgFlag:false,
                    userSpeaking: false
                })

                this.chat_id = ""

                this.stopBroadcast();
                this.props.setStopChatRepId();

                if(this.state.audioStatus === 1 ){
                    this.closeAudio();
                }

                this.setAudioStatus(0,null)

                this.props.setAudioAction("");
            }

            if(this.props.audioAction === "userCloseAudio" ){ // 用户主动关闭录音
                this.setState({
                    content:'',
                    strTxt:'',
                    audioStatus: 0,
                    userSpeaking: false
                })

                this.chat_id = ""

                this.stopBroadcast();
                this.props.setStopChatRepId();

                if(this.state.audioStatus === 1 ){
                    this.closeAudio();
                }

                // this.setAudioStatus(0,null)

                this.props.setAudioAction("");
            }

            if(this.props.audioAction === "changeFunctionPage" ){ //切换到功能页面，这些页面不收音，但是会话不清空
                this.stopBroadcast();
                this.props.setStopChatRepId();

                if(this.state.audioStatus === 1 ){
                    this.closeAudio();
                }

                this.setAudioStatus(0,null)

                this.props.setAudioAction("");
            }

            if(this.props.audioAction === "showFaceCloseAudioStatus" ){ //扫脸的时候只修改audio状态，不会处罚关闭录音，关闭录音再小程序操作
                this.stopBroadcast();
                this.props.setStopChatRepId();

                this.setAudioStatus(0,null)

                this.props.setAudioAction("");
            }

            if(this.props.audioAction === "backHome" ){ //回到初始状态

                this.setState({
                    newList: []
                })
                this.props.setAudioAction("");
            }
        }

        //chatQueue 处理
        // if(this.props.chatQueue && this.props.chatQueue.length > 0){
        //     let data = this.findAndCopy(this.props.chatQueue, this.chat_temp_index)
        //     console.log("------------------execQueue------------------------");
        //     console.log(this.chat_temp_index);
        //     console.log(this.props.chatQueue);
        //     console.log(data);
        //
        //     if(data !== null){
        //         if(!window.Player.current.playing && ( (window.tempPlayStatus === 0 && data.last) ||  !data.last )){
        //
        //             if(this.chat_temp_index === 1){
        //                 this.props.setAudioAction("startBroadcast")
        //             }
        //
        //             this.chat_temp_index ++
        //             //判断是否已经在播报
        //             this.refreshTime();
        //
        //             if(data.voiceData){
        //                 window.Player.current.setData(data.voiceData);
        //
        //                 window.tempPlayStatus = 1;
        //                 window.PlayStats = {
        //                     playCode: data.rep_id,
        //                     status: 1, // 0 ：未播报， 1：已开始播报，2：剧本
        //                     isLast: data.last //是否最后一个播报
        //                 }
        //             }
        //
        //             if(data.last){
        //                 this.props.setAudioAction("endBroadcast")
        //             }
        //             this.props.removeChatQueue(data.index);
        //         }
        //     }
        // }


    }

    refreshState(){
        this.setState({
            temp: new Date().getTime()
        })
    }

    //监听chatList滚动条位置
    chatListScroll=()=>{
        const scrollTop = this.chatListH.current.scrollTop;
        let height=this.chatListH.current.scrollHeight-400
        if (height===scrollTop){
            this.setState({
                downImgFlag:false
            },()=>{
                this.futility()
                this.forceUpdate()
            })
        }else{
            this.setState({
                downImgFlag:true
            },()=>{
                this.futility()
                this.forceUpdate()
            })
        }
        console.log(`当前滚动位置：${scrollTop}`,height===scrollTop,height,this.state.downImgFlag);
    }
    //无用的，但是不要删
    futility(){
        let i=1
        console.log(this.state.downImgFlag)
    }
    //------------------------------------------------------------- 数字人状态和相关动作 start----------------------------------------------------------------
    // 开启设备录音（开始录音)
    startAudio = () => {
        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            // alert("打开小程序语音")
            setTimeout(() => {
                my.postMessage({type:  "audio", act: "start"});
            },500)

        }
    }

    startBroadcastBook(callback){
        this.refreshTime();

        if(this.queue.length > 0 || window.Player.current.playing){
            this.stopBroadcast();
        }

        if(this.state.audioStatus === 1){
            this.closeAudio()
        }

        this.canStopAudio();

        if(callback){
            callback();
        }
    }

    restoreAudio(e){

        // this.setState({
        //     userSpeaking: false,
        //     audioStatus: 0
        // },() => {
        //     /* global my */
        //     if (navigator.userAgent.indexOf('AlipayClient') > -1) {
        //         // alert("close")
        //         my.postMessage({type:"audio",act: "close"});
        //         this.clearJudgeTimer();
        //     }
        // })

        this.props.setAudioAction("userCloseAudio");
    }

    // 关闭设备录音（关闭录音)
    closeAudio(e){
        console.log("关闭语音")
        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            // alert("close")
            my.postMessage({type:"audio",act: "close"});
            this.closeAsrSendListener();
            this.closeAsrCloseListener();
        }
    }

    //数字人停止说话并重新开始录入
    peopleStopTalk(){
        this.props.setAudioAction("stopBroadcast")
    }

    stopBroadcast(){
        this.clearTimer();

        this.queue = [];
        this.chat_temp_index = 0;
        this.chat_text_temp_index = 0;
        this.chat_index = 0;

        this.setState({
            chatRepId: "stop"
        },()=>{
            window.Player.current.stop();
        })
    }

    setAudioStatus = (status,callback) => {
        this.setState({
            audioStatus: status
        },()=>{
            if(callback){
                callback();
            }
        })
    }

    getWsUrl(){
        var baseUrl ='wss://digapitest.sangucloud.com/asr/'+ this.state.sn;
        return baseUrl
    }

    connectWsExc = (callback) => {
        const ws=new WebSocket(this.getWsUrl())
        ws.onopen = () => {
            console.log('WebSocket connected');
            this.refreshAsrCloseTime(); //开启录音总关闭监听
            this.setState({
                content:'',
                strIndex:null,
                strTxt:'',
                isFirstConnect: false
            })
            if(callback){
                callback();
            }
        };

        let that=this
        ws.onmessage= (res)=> {
            if(res.data && res.data.indexOf('command|') > -1){
                this.dealSocketCommand(res.data);
            }

            if(this.state.audioStatus === 1){
                this.refreshTime();
                this.refreshAsrSendTime();

                this.setState({
                    userSpeaking: true
                })

                let obj=JSON.parse(res.data)
                // alert('接收到服务器返回的消息：' + res.data);
                if(that.state.strTxt !== ''){
                    console.log('num',that.strIndex,obj.index);
                    let str=''
                    if(that.state.strIndex === obj.index){
                        str=obj.text
                        this.setState({
                            strTxt:str,
                        })
                        // that.state.strTxt=str
                    }else{
                        let str= that.state.content+that.state.strTxt + ","
                        this.setState({
                            content:str,
                            strIndex:obj.index,
                            strTxt:obj.text
                        })
                    }

                    // if(obj.last && obj.last == 1){
                    //     this.sendAsrText();
                    //
                    //     this.closeAsrSendListener();
                    //     this.closeAsrCloseListener();
                    // }

                }else{
                    this.setState({
                        strIndex:obj.index,
                        strTxt:obj.text
                    })
                }
            }
        }

        ws.onclose =()=>{

            console.log('关闭ws onclose');
        }

        this.setState({
            ws: ws
        })
    }

    dealSocketCommand = (command) => {

        // 扫脸投递
        if(command && command.indexOf('command|showFace|') > -1){
            try{
                let userInfoStr = command.replace('command|showFace|','');
                let userInfo=JSON.parse(userInfoStr)
                if(userInfo && this.state.showList !== 5) {
                    // alert(JSON.stringify(params))
                    this.setState({
                        showList: 5,
                        userInfoData: userInfo,
                        num: 1
                    })
                }
            }catch (e){
                alert("获取支付宝用户信息失败，请重试！")
            }
        }

        // 扫脸查看投递记录
        if(command && command.indexOf('command|recordCheckFace|') > -1){
            try{
                let userInfoStr = command.replace('command|recordCheckFace|','');
                let userInfo=JSON.parse(userInfoStr)
                if(userInfo && userInfo.mobile) {

                    let data = {
                        type:'recordCheckFace',
                        data: userInfo
                    }

                    let event = new CustomEvent('recordCheckFace', { detail:data });
                    window.dispatchEvent(event);

                }



            }catch (e){
                alert("获取支付宝用户信息失败，请重试！")
            }
        }

    }

    //关闭websocket
    closeAsrWebSocket = (callback) => {
        if (this.state.ws && this.state.ws.readyState === WebSocket.OPEN) {
            console.log("关闭ws");
            this.state.ws.close()
            this.closeAsrCloseListener();
            this.closeAsrSendListener()
            if(callback){
                callback();
            }
        }
    }

    // 创建asr关闭监听， 规定时间如果还没有提交，则强制提交
    createAsrCloseListener = () => {
        if(this.asrCloseTimer === null){
            this.asrCloseTimer = setTimeout(() => {

                if(this.state.audioStatus === 1){
                    // alert("AsrCloseListener")
                    this.sendAsrText();
                }

                this.closeAsrSendListener();
                this.closeAsrCloseListener();

            }, 60000);
        }
    }
    refreshAsrCloseTime = () => {
        this.closeAsrCloseListener();
        this.createAsrCloseListener();

    }
    closeAsrCloseListener = () => {
        if(this.asrCloseTimer != null){
            clearTimeout(this.asrCloseTimer)
            this.asrCloseTimer = null;
        }
    }

    // 创建asr提交录音文本监听， 如果2000毫秒未收到 asr文本，则提交
    createAsrSendListener = () => {
        if(this.asrSendTimer === null){

            this.asrSendTimer = setTimeout(() => {
                if(this.state.audioStatus === 1){
                    this.sendAsrText();
                }
                this.closeAsrSendListener();
                this.closeAsrCloseListener();

            }, 2000);
        }
    }
    refreshAsrSendTime = () => {
        this.closeAsrSendListener()
        this.createAsrSendListener()
    }
    closeAsrSendListener = () => {
        if(this.asrSendTimer !== null){
            clearTimeout(this.asrSendTimer);
            this.asrSendTimer = null;
        }
    }

    sendAsrText = () => {
        if(this.state.strTxt){
            window.tempPlayStatus = 0
            window.PlayStats = {
                playCode: "",
                status: 0, // 0 ：未播报， 1：已开始播报
                isLast: false //是否最后一个播报
            }
            let str=this.state.content+this.state.strTxt
            this.closeAudio()
            this.setState({
                content:'',
                strTxt:'',
                audioStatus: 0,
                userSpeaking: false,
                chatRepId: ''
            },()=>{
                this.props.clearChatRepId();
                this.add(str)
            })
        }
    }

    add(e) {
        audioSendVoice.play();
        this.refreshTime();
        if (e) {
            this.addUserAsk(e)
        }else{
            this.addUserAsk(this.state.content)
        }
    }

    addUserAsk(content) {

        // alert("提交的文本是："  +content)

        let item = {
            "reqId": "",
            "content": content,
            "user": "user",
            "status": 0
        }
        let arr=this.state.chatList
        arr.push(item)
        //添加机器人思考loading
        let obj={content:''}
        arr.push(obj)

        let newArr = [];
        newArr.push(obj)

        this.setState({
            newList: newArr,
            chatList:arr,
        },()=> {
            if(!this.state.dialogVisible){
                this.scrollChatToBottom()
            }
            this.ask(content)
        })

    }
    ask = async (content) => {
        if (content) {
            // this.getNewList()
            let param={
                ind_code: this.state.indCode,
                webgl_id: this.state.webGlId,
                content: content,
                position_id:this.state.positionId,
                postion_type:this.state.positionType,
                key: this.state.key
            }
            await this.aiTalk(param);
            console.log(param,this.state.newList)
            // let arr=this.state.newList.pop()


        }
    }

    consoleTime = (text) => {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');

        console.log(text + "---------------- : " + `${hours}:${minutes}:${seconds}`);
        var logText = text + "---------------- : " + `${hours}:${minutes}:${seconds}`;
        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            my.postMessage({type: "log", act: logText});
        }
    }

    aiTalk = async (params) => {

        //params = { ind_code: '', webgl_id: 0, position_id: 0, content: ''}
        let chat_id = this.getChatId();

        window.PlayStats = {
            playCode: "",
            isLast: false //是否最后一个播报
        }
        this.changPlayStatus("start")

        // alert(str)
        this.consoleTime("开始访问dify");
        const response = await fetch('/digApi/digital_dify/chat_word_v2', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            dataType: "text/event-stream",
            body: JSON.stringify({
                "chat_id": chat_id,
                "user_id": "xiaogu",
                "ind_code": params.ind_code,
                "position_id": this.state.positionId ? this.state.positionId.toString(): "",
                "position_type": this.state.positionType ? this.state.positionType.toString(): "",
                "webgl_id": params.webgl_id,
                "key": this.state.chatType === 3 ? this.state.psyConKey: params.key,
                "content": params.content
            })
        });

        this.chat_index = 0
        this.chat_temp_index = 1
        this.chat_text_temp_index = 1;

        if(!response.ok){
            throw new Error(`HTTP error! status: ${response.status}`);
            let data = {
                index: this.chat_index,
                voiceData: "",
                last: true,
                chatObj: null,
                rep_id: "",
                openAudio: true
            }
            this.queue.push(data)
        }

        this.consoleTime("dify返回结果");

        const reader = response.body.getReader();
        let decoder = new TextDecoder();
        let result = true;

        while (result) {
            const { done, value } = await reader.read();
            // console.log("--------------done---------------:{}",done);
            result = !done;


            this.refreshTime();

            console.log("this.state.chatRepId ----------------------------");
            console.log(this.state.chatRepId );

            if(this.state.chatRepId === "stop"){
                result = false
                continue;
            }

            if(done){
                this.chat_index ++;
                // window.tempPlayStatus = 1
                let data = {
                    index: this.chat_index,
                    voiceData: "",
                    last: true,
                    chatObj: null,
                    rep_id: "",
                    openAudio: true
                }
                this.queue.push(data)
                // this.props.addChatQueue(data)

            }

            // try {
            let text = decoder.decode(value);
            console.log("------------dify 返回内容------------")
            console.log(text)
            // alert(text)
            if(text){ //现在如果dify直接返回空则是 无法回答， 之后应该是固定的文案
                // text = text.replace("data: ", "")
                const formattedData = text.replace(/\n/g, '。');
                const dataEntries = text.trim().split('data: ').slice(1);

                for(var i=0; i < dataEntries.length; i++){
                    let entry = dataEntries[i];

                    try{
                        let json = JSON.parse(entry);
                        // console.log(json)
                        json.last = false;
                        this.chat_index ++;
                        await this.difyTalk(json, this.chat_index);
                        if (!json.params||!this.state.dialogVisible){
                            this.scrollChatToBottom()
                        }

                    }catch (e){
                        console.log(e)
                    }
                }

            }

            // }catch (e) {
            //     console.log(e)
            // }

        }
    }

    difyTalk = async (jsonStr,index) => {
        try{
            await this.aiPlay(jsonStr.content, jsonStr.reqId,jsonStr,index);
        }catch (e) {
            console.log(e)
            let data = {
                index: index,
                voiceData: "",
                last: false,
                chatObj: null,
                rep_id: "",
                openAudio: true
            }
            this.queue.push(data)
            // this.props.addChatQueue(data)
        }

    }

    outputUnable = (callback) => {

        //TODO 需要根据首页和弹框页面 操作不同的聊天记录对象
        let item = {
            "reqId": '',
            "content": '无法回答您的问题',
            "user": "assistants",
            "status": 1,
            'params':''
        }

        let arr=this.state.chatList
        let arrNew=this.state.newList
        arr[arr.length -1] = item;
        arrNew[arr.length -1] = item;
        this.setState({
            chatList:arr,
            newList:arrNew,
            messageSend:false,
            stopFlag:true,
            voiceFlag:true
        },() => {
            if(callback){
                callback();
            }
        })
    }

    // 数字人播报
    aiPlay = async (content,rep_id,jsonStr,index) => {
        //无法回答您的问题
        if(content.indexOf("11111111111") !== -1){
            this.outputUnable(() => {

                let data = {
                    index: index,
                    voiceData: "",
                    last: false,
                    chatObj: null,
                    rep_id: "",
                    openAudio: true
                }
                this.queue.push(data)
                // this.props.addChatQueue(data)

                if(this.state.dialogVisible){
                    this.backHome(true,true,false)
                }

            })

        }else{

            if(index === 1){
                this.consoleTime("第一次从ali访问播报数据start");
            }

            this.showChatText(jsonStr)

            await chatApi.getChatData(content).then(res => {

                if(index === 1){
                    this.consoleTime("第一次从ali访问播报数据end");
                }
                if(this.state.chatRepId !== "stop"){
                    if(res.data && res.data.voiceBroadcastResult){
                        let voiceData = window.Lingjing.Tools.storyFormat(res.data.voiceBroadcastResult)

                        let data = {
                            index: index,
                            voiceData: voiceData,
                            last: jsonStr.last,
                            chatObj: jsonStr,
                            rep_id: rep_id,
                            openAudio: true
                        }
                        this.queue.push(data)
                        // this.props.addChatQueue(data)

                        this.createTimer();
                        console.log(this.state.chatList,'chatList')
                    }else{
                        // alert("未")
                        let data = {
                            index: index,
                            voiceData: null,
                            last: jsonStr.last,
                            chatObj: jsonStr,
                            rep_id: rep_id,
                            openAudio: true
                        }
                        this.queue.push(data)
                    }
                }
            })
        }


    }

    showChatText(jsonStr){
        var type = 0; // 0: 首页 1：弹层页面

        if(this.state.showList < 4 && this.state.showList > 0) {
            type =1;
        }

        let reqId = jsonStr.reqId;
        let textArr = jsonStr.content.split('');

        let lastItem = this.state.chatList[this.state.chatList.length - 1] ;
        let arr = this.state.chatList ;

        if (lastItem && lastItem.reqId && lastItem.reqId === reqId) {
            for (let i = 0; i < textArr.length; i++) {
                lastItem.content += textArr[i];
            }
            arr[arr.length - 1]=lastItem
        }else {

            for (let i = 0; i < textArr.length; i++) {
                if (i == 0) {
                    let item = {
                        "reqId": reqId,
                        "content": textArr[i],
                        "user": "assistants",
                        "status": 1,
                        "params":jsonStr.params
                    }
                    arr[arr.length -1] = item
                } else {
                    arr[arr.length-1].content += textArr[i];
                }
            }
            lastItem=arr[arr.length-1]
        }

        this.setState({
            chatList:arr,
        })

        if(jsonStr.params){
            let newArr = [];
            newArr.push(arr[arr.length - 1])
            this.setState({
                newList:newArr,
                toWork:0
            })
            // type === 0 &&
            if( jsonStr.params){
                this.openShow(1,jsonStr.params)
            }else{

                //刷新页面
                let chatObj = this.getChatParams(jsonStr.params)
                let refreshCount = this.state.refreshCount;
                refreshCount ++
                this.setState({
                    chatObj:chatObj,
                    refreshCount:refreshCount
                })
            }
        }else{
            if(type === 1 ){
                if(!this.state.positionId){
                    this.backHome(true,true,false)
                }else{
                    let newArr = [];
                    newArr.push(arr[arr.length - 1])
                    this.setState({
                        newList:newArr
                    })
                }

            }
        }
        this.scrollChatToBottom();

    }


    getChatId = () => {

        console.log("----------------chat_id------------------");
        console.log(this.chat_id);

        if(!this.chat_id){
            this.chat_id = uuidv4();
        }

        return this.chat_id;
    }

    createTimer = () => {
        if(this.timer == null){
            this.timer = setInterval(() => {
                this.execQueue();
            },100)
        }

        // if(this.chatTexTtimer == null){
        //     this.chatTexTtimer = setInterval(() => {
        //         this.execQueueChatText();
        //     },100)
        // }
    }

    clearTimer = () => {
        clearInterval(this.timer);
        this.timer = null;

        clearInterval(this.chatTexTtimer);
        this.chatTexTtimer = null;
    }

    // 滚动底部
    scrollChatToBottom = () => {
        console.log(5555)
        if(this.chatListRef.current !== undefined && this.chatListRef.current.scrollIntoView !== undefined){
            this.chatListRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    aiBroadcast = (voiceData,openAudio) => {

        this.startBroadcastBook(() => {



            // this.props.clearChatRepId();

            let data = {
                index:1,
                voiceData: voiceData,
                last: false,
                chatObj: null,
                rep_id: "",
                openAudio: true,
                chatRepId: ""
            }

            let last = {
                index:2,
                voiceData: "",
                last: true,
                chatObj: null,
                rep_id: "",
                openAudio: openAudio
            }

            this.chat_temp_index = 1;
            this.chat_text_temp_index = 1;

            this.queue.push(data)
            this.queue.push(last)
            // this.props.addChatQueue(data)
            // this.props.addChatQueue(last)

            // console.log(this.queue);

            this.createTimer();
        })
    }

    aiBroadcastByKey = (key) => {
        // window.PlayStats = {
        //     playCode: "",
        //     status: 2, // 0 ：未播报， 1：已开始播报，2：剧本
        //     isLast: false //是否最后一个播报
        // }
        let item = this.getBroadItem(key)

        if(item){
            let voiceItem = localStorage.getItem(key)

            if(voiceItem){
                const voiceData = JSON.parse(localStorage.getItem(key));
                this.aiBroadcast(voiceData, item.openAudio);
            }else{
                chatApi.getBroadcast(item.value).then(res => {

                    if(res.data && res.data.voiceBroadcastResult){
                        let voiceData = window.Lingjing.Tools.storyFormat(res.data.voiceBroadcastResult)
                        this.aiBroadcast(voiceData, item.openAudio);
                    }
                })
            }
        }

        // let item = this.getBroadItem(key)
        //
        // chatApi.getBroadcast(item.value).then(res => {
        //
        //     if(res.data && res.data.voiceBroadcastResult){
        //         let voiceData = window.Lingjing.Tools.storyFormat(res.data.voiceBroadcastResult)
        //         this.aiBroadcast(voiceData, item.openAudio);
        //     }
        // })
    }

    execQueue = () => {
        if(this.queue && this.queue.length > 0){

            let data = this.findAndCopy(this.queue, this.chat_temp_index)
            // console.log("------------------execQueue------------------------");
            // console.log(this.queue);
            // console.log(data);
            if(data !== null) {

                this.refreshTime("-----window.tempPlayStatus----" +window.tempPlayStatus);
                if (!window.Player.current.playing && window.tempPlayStatus === 0) {

                    // 更换成播报中的状态
                    // if(this.chat_temp_index === 1){
                    //     this.props.setAudioAction("startBroadcast")
                    // }

                    console.log()

                    if (data.voiceData && this.state.audioStatus === 0 ) {
                        window.tempPlayStatus = 1;
                        window.Player.current.setData(data.voiceData);

                        window.PlayStats = {
                            playCode: data.rep_id,
                            status: 1, // 0 ：未播报， 1：已开始播报，2：剧本
                            isLast: data.last //是否最后一个播报
                        }
                    }

                    this.chat_temp_index ++

                    if (data.last) {
                        if(data.openAudio){
                        //     this.props.setAudioAction("endBroadcast")
                        // }else{
                            this.props.setAudioAction("changeFunctionPage")
                        }
                        this.queue = [];
                    }

                }
            }
        }
        else{

        }
    }

    execQueueChatText = () => {
        if(this.queue && this.queue.length > 0){

            let data = this.findAndCopy(this.queue, this.chat_text_temp_index)
            // console.log("------------------execQueue text------------------------");
            // console.log(data);
            if(data !== null) {
                this.chat_text_temp_index ++;
                this.refreshTime();

                if(data.chatObj !== null){
                    this.showChatText(data.chatObj)
                }
            }
        }
        else{

        }
    }

    removeQueue = (index) => {
        let tempIndex = -1;
        for(var i= 0; i < this.queue.length; i++){
            let data = this.queue[i];
            if(data.index === index){
                tempIndex = i;
            }
        }
        this.queue.slice(tempIndex,1)
    }


    //------------------------------------------------------------- 数字人状态和相关动作 end----------------------------------------------------------------


    createBackTimer = (type) => {

        if(this.backTimer === null){
            console.log("---------------createBackTimer-------------------")
            this.backTimeType = type;
            let config = this.getTimerConfig(type)

            this.setState({
                backTime: config.time
            })
            this.backTimeLimit = config.time;

            this.backTimer = setInterval(() => {

                // console.log("----------倒计时：" + this.state.backTime)

                this.setState({
                    backTime: this.state.backTime - 1
                })
                if(this.state.backTime <= 0){

                    if(this.state.mainDialogVisible === false){
                        if(this.backTimeType === "home"){
                            this.backHome(false,false,true);
                        }else{
                            this.backHome();
                        }
                    }

                    this.clearBackTimer();
                }
            },1000)
        }
    }

    getTimerConfig = (type) => {

        for(let i=0; i < this.backTimeConfig.length; i++){
            let item = this.backTimeConfig[i];
            if(item.type === type){
                return item;
            }
        }

        return null;
    }

    getBroadItem = (key) => {

        for(let i=0; i < this.broadKeys.length; i++){
            let item = this.broadKeys[i];
            if(item.key === key){
                return item;
            }
        }

        return null;
    }

    clearBackTimer = () => {
        clearInterval(this.backTimer);
        this.backTimer = null;
        this.setState({
            backTime: 0
        })
        this.backTimeType = "";
    }

    refreshTime = () => {

        if(this.backTimeType !== ""){
            // alert(this.backTimeType);
            // alert(this.backTimeLimit);
            this.setState({
                backTime: this.backTimeLimit
            })
        }
    }

    moveReducePeople(){
        if(window.Player && window.Player.current && window.Player.current.avatar){
            window.Player.current.avatar.setScale(0.5,0.5)
            window.Player.current.avatar.setPosition(-150, -450, 0, true)
            window.Player.current.avatar.setRotation(0, 5, 0)
        }


    }
    moveMagnifyPeople(){
        if(window.Player && window.Player.current && window.Player.current.avatar) {
            window.Player.current.avatar.setScale(1, 1)
            window.Player.current.avatar.setPosition(0, 0, 0, true)
            window.Player.current.avatar.setRotation(0, 0, 0)
        }
    }

    moveLeft(){
        let obj=document.getElementById('ljbox')

        window.Player.current.avatar.setScale(1,1)
        window.Player.current.avatar.setPosition(-100, 0, 0, true)
        window.Player.current.avatar.setRotation(0, 0, 0)
    }

    moveCenter(){
        if(window.Player && window.Player.current && window.Player.current.avatar) {

            window.Player.current.avatar.setScale(1, 1)
            window.Player.current.avatar.setPosition(0, 0, 0, true)
            window.Player.current.avatar.setRotation(0, 0, 0)
        }
    }

    handlePlayChange = (res) => {

        if(res.detail){
            this.changPlayStatus(res.detail.data)
        }
    }

    handleSceneReady = (res) => {
        console.log("handleSceneReady-----------------------------------")
        // if(res.detail.data){
        //     this.setState({
        //         digReady: true
        //     })
        // }
        var mainUrl = localStorage.getItem("MainUrl");
        var defaultToMain = localStorage.getItem("DefaultToMain");
        // var data = {
        //     "mainUrl": mainUrl,
        //     "defaultToMain": defaultToMain
        // }
        console.log(mainUrl)
        this.setState({
            mainUrl:mainUrl,
            loading: false
        })
        // /* global my */
        // if (navigator.userAgent.indexOf('AlipayClient') > -1) {
        //     // alert("close")
        //     my.postMessage({type:"sceneReady",data:data});
        // }

        if(mainUrl && defaultToMain === "1"){
            this.setState({
                mainDialogVisible:true,
                logoShow:false
            })
        }
    }

    closeMain = () => {
        this.setState({
            mainDialogVisible:false,
            logoShow:true
        })
    }

    handleAudio = (res) => {

        if(res.detail.data){
            this.sendVoice(res.detail.data)
        }
    }

    handleAudioTest = (res) => {
        audioApi.voiceToTextTxBase64(res.detail.data).then(res => {
            // alert(JSON.stringify(res))
            if( res.data.code === "200"){
                let content = res.data.data;
                // alert(content)
                this.userTalk(content)
            }

        })
    }

    userTalk(e){
        this.setState({
            content:e
        })
        this.add(e)
    }

    // 扫脸 获取用户信息
    handleFaceResult = (res) => {
        // this.refreshTime();
        let params = res.detail.data

        // let params = {
        //     gender: "",
        //     user_name: "李洋",
        //     mobile: "18600912106",
        //     id_card: "130429182737261726"
        // }
        if(params && this.state.showList !== 5) {
            // alert(JSON.stringify(params))
            this.setState({
                showList: 5,
                userInfoData: params,
                num: 1
            })
        }
    }

    handleFaceResult2 = (res) => {
        // this.refreshTime();
        let paramData = res.detail.data;

        let obj = {
            "code": paramData.code,
            "appId": paramData.appId
        }

        sgAuthApi.getUserInfo(obj).then(ret => {



            let resData = ret.data;
            if(resData.code === 200) {
                console.log(resData);
                console.log("---------------------获取用户信息--------------")
                let data = resData.data;

                let userInfo = {};
                userInfo.indCode = paramData.indCode;
                userInfo.positionId = paramData.positionId;
                userInfo.projectId = paramData.projectId;
                userInfo.positionType = paramData.positionType;
                userInfo.openId = data.openId;

                if (data.gender) {
                    switch (data.gender) {
                        case "F":
                            userInfo.gender = "女";
                        case "M":
                            userInfo.gender = "男";
                        default:
                            userInfo.gender = "";
                    }
                } else {
                    userInfo.gender = "";
                }

                userInfo.user_name = data.userName;
                userInfo.nick_name = data.nickName;
                userInfo.mobile = data.mobile;

                if (data.certType == "0") {
                    userInfo.id_card = data.certNo;
                }

                if(userInfo && this.state.showList !== 5) {
                    // alert(JSON.stringify(params))
                    this.setState({
                        showList: 5,
                        userInfoData: userInfo,
                        num: 1
                    })
                }

            }else{
                alert("获取用户信息失败")
            }
        })


    }

    infraredCheck = (res) => {
        // if(res.detail.data){
        //
        //     if(res.detail.data === "1"){
        //
        //         if(this.state.infraredStatus === 0){
        //             // alert("进入到红外识别方法")
        //             this.refreshTime()
        //             if(this.state.audioStatus === 0 && !this.state.dialogVisible){
        //                 this.props.setTimerType("home")
        //                 this.aiBroadcastByKey("hello")
        //
        //             }
        //
        //             this.setState({
        //                 infraredStatus: 1
        //             })
        //         }
        //     }else{
        //         if(this.state.infraredStatus === 1){
        //
        //             // this.props.setAudioAction("userCloseAudio")
        //
        //             this.setState({
        //                 infraredStatus: 0
        //             })
        //         }
        //     }
        // }
    }

    // 扫身份证 获取用户信息
    handleIdCardCheck = (res) => {
        // alert(JSON.stringify(res.detail.data))
        if(res.detail.data) {
            this.setState({
                scanPop: res.detail.data.showScan,
                scanStatus: res.detail.data.scanType,
                showList: 5,
                showApplyWay: false,
                modalBodyStyle:{
                    height:'80vh'
                },
            })
            if(!res.detail.data.showScan) {
                this.setState({
                    num: 3,
                    showList: 5,
                    idCardInfoData: res.detail.data,
                    scanPop: res.detail.data.showScan,
                    scanStatus: res.detail.data.scanType,
                    showApplyWay: false,
                    modalBodyStyle:{
                        height:'80vh'
                    },
                })
            }
        }
    }

    changPlayStatus = (type) => {
        if (type==='end'){
            // alert("播报完成" + type)
            this.stopInitData()
        }
    }

    componentWillUnmount() {
        // alert("unmount")
        this.hideCom()

        console.log("离开了voice页面 ");
    }

    hideCom=() => {

        this.clearTimer();
        this.clearBackTimer();
        this.closeAsrSendListener();
        this.closeAsrCloseListener();

        //清空websocket心跳
        this.clearHeartCheck();
        this.closeAsrWebSocket();
    }

    //初始化
    init=()=>{
        // let param={
        //     webGlId:this.state.webGlId,
        //     a:Math.random(),
        // }
        // positionAi.getDigToken(param).then(res=>{
        //     if (res.data.code==200){
        //         let obj=res.data.data
        //         this.setState({
        //             indCode:obj.indCode,
        //         })
        //         this.setState({
        //             exampleList:obj.prompts[11],
        //             workGreeting:obj.prompts[2][0],
        //             //查政策
        //             policyGreeting:obj.prompts[4][0],
        //         })
        //     }
        // });
        // this.recReq()
    }

    clearChat = () => {
        this.chat_id = null;
    }


    // 数字人播报完成需要调用的方法
    broadEnd = () => {
        if(this.state.showList <= 4){
            this.stopInitData()
            // alert("last")
        }else{
            this.setState({
                audioStatus: 0
            })
        }
    }

    copy = (data) => {
        let copystr = JSON.stringify(data);
        let copy = JSON.parse(copystr);
        return copy;
    }

    findAndCopy = (list,index) => {

        if(list && list.length > 0){
            for(var i=0 ;i < list.length;i++){
                let data = list[i];

                if(data.index === index){
                    return this.copy(data);
                }
            }
        }

        return null;

    }


    //停止后初始化数据
    stopInitData=()=>{
        // alert("stopInitData")
        window.PlayStats.status = {
            playCode: "",
            status: 0, // 0 ：未播报， 1：已开始播报，2：剧本
            isLast: false
        };
        this.setState({
            audioStatus: 1,  // 0: 未收音，  1：开始收音  2：思考中（停止收音）
            userSpeaking: false,
            aiThink: false,
        },()=>{
            // this.initAudio()
            // this.setState({
            //     scriptSpeech:false
            // })
        })
    }
    //录音授权
    recReq=()=>{
        // if (navigator.userAgent.indexOf('AlipayClient') <= -1) {
        //     RecordApp.RequestPermission(()=>{
        //         console.log("已获得录音权限，可以开始录音了");
        //     },(msg,isUserNotAllow)=>{
        //         if(isUserNotAllow){//用户拒绝了录音权限
        //             //这里你应当编写代码进行引导用户给录音权限，不同平台分别进行编写
        //         }
        //         console.error("请求录音权限失败："+msg);
        //     });
        // }
    }
    toAudio (){
        console.log(this.state.audioFlag,this.state.stopFlag,this.state.voiceFlag);
        if(this.state.audioFlag){
            this.stopOrAddVoice()
            //取消录音
            this.recStop()
            this.setState({
                content:'',
                strIndex:null,
                strTxt:'',
                overTime:null,
                timeJudge:null,
                newList:[],
                audioFlag:false,
                voiceFlag:true,
                beginAudio:false
            })
        }else{
            if(this.state.voiceFlag&&!this.state.stopFlag){
                // this.initAudio()
                this.setState({
                    newList:[],
                })
            }
        }
        if (this.state.clickXBack){
            //关闭弹窗后再次关闭
            this.setState({
                clickXBack:false
            })
        }
    }

    // 提示音
    stopOrAddVoice(){
        audioVoiceClick.play();
    }

    // 用户点击开始收音
    addVoice = () => {
        if (this.addTimer){
            return
        }else{

            //点击开始收音的时候，应该开启60关闭记时
            this.props.setTimerType("home")

            console.log('start begin')
            audioVoiceClick.play();
            this.props.setTimerType("home");

            this.props.setAudioAction("startAudio");
        }
        this.addTimer=setTimeout(()=>{
            clearTimeout(this.addTimer)
            this.addTimer=null
        },1000)
    }

    setAsr(type,buffers,powerLevel){
        var blob=new Blob(buffers,{type:type})
        let smallBlob=null
        if(this.state.sizeVoice){
            smallBlob= blob.slice(this.state.sizeVoice, blob.size,'audio/mpeg');
            // this.state.sizeVoice=blob.size
            this.setState({
                sizeVoice:blob.size,
            })
        }else{
            this.setState({
                sizeVoice:blob.size,
            })
            // this.state.sizeVoice=blob.size
            smallBlob=blob
        }
        this.sendVoice(smallBlob)
        if(powerLevel>0){
            this.state.animationDuration = '0.5s'; // 改为新的动画时间
        }
    }

    //停止录音
    recStop(){
        if(this.state.audioStatus !==1 ){
            return;
        }

        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            // alert("close")
            my.postMessage({type:"audio",act:"close"});
            this.clearJudgeTimer();
            this.setState({
                voiceFlag:false,
                beginAudio:false,
                animationDuration:'1s'
            })
        }else {
            // RecordApp.Stop((arrayBuffer,duration,mime)=>{
            //     if(typeof(Blob)!="undefined" && typeof(window)=="object"){
            //         this.setState({
            //             voiceFlag:false,
            //             beginReturn:false,
            //             animationDuration:'1s'
            //         })
            //         if (ws && ws.readyState === WebSocket.OPEN) {
            //             ws.close()
            //         }
            //     }
            // },(msg)=>{
            //     console.error("结束录音失败："+msg);
            // });
        }
    }

    // 支付宝 扫脸
    checkFaceAndGetUserInfo = (e) =>{
        if(this.faceBtnTimer){
            return
        }else {
            this.btnClickVoice()
            this.props.setAudioAction("showFaceCloseAudioStatus")
            // this.props.setAudioAction("changeFunctionPage")
            this.refreshTime();
            setTimeout(()=>{
                /* global my */
                if (navigator.userAgent.indexOf('AlipayClient') > -1) {
                    my.postMessage({type:"showFace"});
                }
            },200)
        }
        this.faceBtnTimer=setTimeout(()=>{
            clearTimeout(this.faceBtnTimer)

            this.faceBtnTimer=null
        },1000)


        e.stopPropagation();

        // this.handleFaceResult();
    }

    // 扫描 身份证
    checkIdCardInfo = () => {
        if(this.cardBtnTimer){
            return
        }else{
            this.btnClickVoice()
            this.props.setAudioAction("showFaceCloseAudioStatus")

            /* global my */
            if (navigator.userAgent.indexOf('AlipayClient') > -1) {
                my.postMessage({type:"idCardCheck"});
            }
        }
        this.cardBtnTimer=setTimeout(()=>{
            clearTimeout(this.cardBtnTimer)

            this.cardBtnTimer=null
        },1000)
    }


    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    //持续1秒用户未说话则发送大模型
    chatStop(){
        if (this.state.overTime != null) {
            this.clearOverTime()
        }
        this.overTime=setTimeout(()=>{
            console.log('60秒内没有发送数据');
            this.recStop()
            this.setState({
                overTime:null,
                audioFlag:false,
                voiceFlag:true,
                clickXBack:false

            })
        },60000)
    }
    sendVoice(blob){
        if(this.state.audioStatus === 1){
            if (this.state.ws && this.state.ws.readyState === WebSocket.OPEN) {
                this.state.ws.send("{mp3}" + blob);
            }
            // else{
            //     this.connectAsrWebSocket(() => {
            //         this.state.ws.send("{mp3}" + blob);
            //     })
            // }
        }else{
            this.closeAudio();
        }
    }

    sendSocketMsg(msg){

        if (msg && this.state.ws && this.state.ws.readyState === WebSocket.OPEN) {
            this.state.ws.send(msg);
        }

    }

    //点击找工作声音
    btnClickVoice(){
        const audioBtnClick = new Audio(btnClick);
        audioBtnClick.play();
    }
    //点击工作和政策
    toExample=(e)=>{
        //e:1工作，2政策
        //停止说话
        // this.btnClickVoice()
        // this.breakVoice()



        let str=''
        let toWork=null
        if(e==1){
            // item.content=this.state.workGreeting
            str='positionSearch'

            let indCode = localStorage.getItem("IND_CODE");

            if(indCode ){
            // && indCode !== 'IND_1025'
                toWork=1
                this.openShow(1,'positionSearch')
            }

            if(this.state.chatType ===3 ){
                let psyConChatList = this.state.chatList;
                let positionChatList = this.state.positionChatList;
                this.setState({
                    psyConChatList:psyConChatList,
                    chatList:positionChatList,
                    chatType:1
                },() => {
                    this.scrollChatToBottom();
                })


            }

        }else if(e==2){
            // item.content=this.state.policyGreeting
            str='policy'

            if(this.state.chatType ===3 ){
                let psyConChatList = this.state.chatList;
                let positionChatList = this.state.positionChatList;
                this.setState({
                    psyConChatList:psyConChatList,
                    chatList:positionChatList,
                    chatType:2
                },() => {
                    this.scrollChatToBottom();
                })

            }

        }else if(e==3){
            // item.content=this.state.policyGreeting
            str='psyCon'
            if(this.state.chatType ===1 || this.state.chatType ===2 ){
                let psyConChatList = this.state.psyConChatList;
                let positionChatList = this.state.chatList;
                this.setState({
                    positionChatList:positionChatList,
                    chatList:psyConChatList,
                    chatType:3
                },() => {
                    this.scrollChatToBottom();
                })

            }
        }
        // arr[1]=item
        this.setState({
            // newList: arr,
            stopFlag:true,
            beginAudio:false,
            messageSend:false,
            voiceFlag:true,
            clickPolicy:true,
            toWork: toWork
        })
        // this.forceUpdate()
        setTimeout(()=>{
            this.props.setTimerType("home")
            this.aiBroadcastByKey(str)
        },300)
        // this.aiPlay(item.content)

    }
    //中断语音,清空对话
    breakVoice(){
        this.props.setAudioAction("resetStatus")
    }

    backHome(keepChat,notVoice,isHome){
        localStorage.removeItem('positionTypeInt')
        localStorage.removeItem('listSearch')

        this.handleCleanScreenAll()

        if(!notVoice){
            this.btnClickVoice()
        }

        this.setState({
            stopRobToBegin:true,
            showApplyWay:false,
            beginAudio:false,
            stopFlag:false,
            positionId:null,
            positionType:null,
            dialogVisible:false,
            btnShow:false,
            content:'',
            strIndex:'',
            strTxt:'',
            overTime:null,
            showList:0,
            modalBodyStyle:{
                height:'80vh'
            },
            backTo:null,
            poisitinInfoData:{},
            btnWork:false,
            mobile:'',
            logoShow:true,
            showRecord:false
        })

        if(!keepChat){
            this.props.setAudioAction("resetStatus")
        }

        if(this.newest && this.newest.current){
            this.newest.current.style.top='30vh';
            this.newest.current.style.right='4vw';
            this.newest.current.style.left='auto';
        }

        if(!isHome){
            this.props.setTimerType("home")
        }
    }

    clearJudgeTimer(){
        if (this.state.timeJudge != null) {
            clearTimeout(this.state.timeJudge);
            this.state.timeJudge = null;
        }
    }

    clearOverTime(){
        if (this.state.overTime != null) {
            clearTimeout(this.state.overTime);
            this.state.overTime = null;
        }
    }

    //获取最新数据
    getNewList(){
        let userNum=null
        let otherNum=null
        let arr=[]
        this.state.chatList.map((el,index)=>{
            if(el.user=='user'){
                userNum=index
            }else{
                otherNum=index
            }
        })

        let obj={content:''}

        if(userNum != null && userNum>=0){
            arr.push(this.state.chatList[userNum])
        }
        if(otherNum != null && otherNum>=0){
            arr.push(this.state.chatList[otherNum])
        }else {

            arr.push(obj)

        }
        console.log(arr,'arr',otherNum)
        this.setState({
            newList:arr
        })
    }

    sendcode(mobile){
        sgAuthApi.sendVerfyCode(mobile).then(res => {
            alert("发送成功")
        })
    }
    //弹窗展示
    openShow(e,params, history){

        if(history){
            this.setState({
                newList: []
            })
        }

        this.btnClickVoice()
        console.log(e)
        let arr=[]
        let obj = {};
        let num=null
        let refreshCountNum=this.state.refreshCount
        if (e>=4){
            num=e

            if (e===4){
                this.props.setAudioAction("changeFunctionPage")
                this.setState({
                    modalBodyStyle:{
                        height:'80vh'
                    },
                    showRecord:true
                })
            }
        }else {
            this.selectScreenData = {
                workingLife: '',    // 工作性质
                education: '',    // 学历要求
                salary: '',    // 薪资要求
            }
            if (params&&params.indexOf('positionSearch')!==-1){
                obj = this.getChatParams(params)
                refreshCountNum++
                num=1
            }else if (params&&params.indexOf('enterpriseSearch')!==-1){
                let str=params.replace('enterpriseSearch|','')
                arr=str.split("|");
                num=2
                for (let i = 0; i < arr.length; i++) {
                    let key = arr[i].substring(0, arr[i].indexOf('='));
                    let value = arr[i].substring(arr[i].indexOf('=') + 1);
                    obj[key] = value;
                }
            }else if (params&&params.indexOf('enterpriseSearch')!==-1){
                let str=params.replace('resumeSearch|','')
                arr=str.split("|");
                num=3
                for (let i = 0; i < arr.length; i++) {
                    let key = arr[i].substring(0, arr[i].indexOf('='));
                    let value = arr[i].substring(arr[i].indexOf('=') + 1);
                    obj[key] = value;
                }
            }
        }

        // this.recStop()
        console.log(obj, 'obj数据');
        console.log(arr, 'arr数据');

        this.setState({
            chatObj: obj,
            stopRobToBegin:false,
            dialogVisible:true,
            showList:num,
            showTip:0,
            listShow:true,
            clickXBack:false,
            clickPolicy:false,
            refreshCount:refreshCountNum,
        },()=>{
            this.newest.current.style.top='7.5vh';
            this.newest.current.style.right='4vw';
            this.newest.current.style.left='auto';
        })
    }

    getChatParams(e){
        let arr=[]
        let obj = {};

        let str=e.replace('positionSearch|','')
        arr=str.split("|");

        for (let i = 0; i < arr.length; i++) {
            // 获取当前部分中等号之前的内容作为属性名
            let key = arr[i].substring(0, arr[i].indexOf('='));
            // 获取当前部分中等号之后的内容作为属性值
            let value = arr[i].substring(arr[i].indexOf('=') + 1);
            // 设置属性及其值到对象中
            obj[key] = value;
        }

        return obj
    }

    //关闭弹窗
    closePop = () => {
        this.btnClickVoice()
        if(this.state.showList < 6) {
            this.handleCleanScreenAll()
        }
        if(this.state.showList!==6&&this.state.showList!==7){
            localStorage.removeItem('positionTypeInt')
            localStorage.removeItem('listSearch')
        }
        if(this.state.scanPop) {    // 身份证关闭
            // alert("userCloseAudio")
            this.props.setAudioAction("userCloseAudio")
            this.setState({
                showList:1,
                scanPop: false,
                showApplyWay: true,
                showRecord:false
            },() => {
                /* global my */
                if (navigator.userAgent.indexOf('AlipayClient') > -1) {
                    my.postMessage({type:"closeIdCard"});
                }
            })

        }else {

            // this.stopBroadcast();
            if (this.state.showList<=4){
                // this.recStop()
                this.props.setAudioAction("userCloseAudio")
                this.setState({
                    backTo:null,
                    dialogVisible:false,
                    strIndex:null,
                    strTxt:'',
                    newList:[],
                    stopRobToBegin:true,
                    showApplyWay:false,
                    beginAudio:false,
                    stopFlag:false,
                    audioFlag:false,
                    voiceFlag:true,
                    positionId:null,
                    positionType:null,
                    listShow: true,
                    poisitinInfoData: {},
                    showList:0,
                    btnWork:false,
                    modalBodyStyle:{
                        height:'80vh'
                    },
                    showRecord:false

                })
                // alret(JSON.stringify(this.setState))
                // this.forceUpdate();
                //停止说话

                this.newest.current.style.top='30vh';
                this.newest.current.style.right='4vw';
                this.newest.current.style.left='auto';

                this.refreshState()
            }else if(this.state.showList==6||this.state.showList==7){
                this.closePositionInfo()
            }else if(this.state.showList ==8) {
                this.setState({
                    dialogVisible:false,
                    showList:0,
                    showApplyWay:false,

                })
            } else{
                // alert("userCloseAudio")
                this.props.setAudioAction("userCloseAudio")
                this.setState({
                    listShow: false,
                    showApplyWay: true,
                    showList:1,
                    modalBodyStyle:{
                        height:'80vh'
                    },
                    showRecord:false
                })
            }

        }

        this.setState({
            clickXBack:true,
            showRecordInfo: false
        },() => {
            // this.props.setAudioAction("stopBroadcast")
        })

    }

    // 点击筛选 打开新弹窗
    handleScreen = () => {
        this.setState({
            showList: 7
        })
    }

    // 筛选选择后 回调函数
    handleSelectScreen = (val) => {
        this.setState({
            showList: 1
        })
        this.selectScreenData = {
            workingLife: val.workingLife,    // 工作性质
            education: val.education,    // 学历要求
            salary: val.salary,    // 薪资要求
        }
    }

    // 点击地区 打开新弹窗
    handleArea = (val) => {
        this.screenAreaParams = val;
        this.setState({
            showList: 6
        })
    }

    // 地区选择后 回调函数
    handleSelectArea = (province, city, county) => {
        this.setState({
            showList: 1
        })
        this.areaData.province = province;
        this.areaData.city = city;
        this.areaData.county = county;
        console.log('地区返回值====>' ,province, city, county);
    }

    // tabs切换 清空所有筛选
    handleCleanScreenAll = () => {
        // 清空地区
        this.areaData = {
            province: {
                value: '',
                label: ''
            },
            city: {
                value: '',
                label: ''
            },
            county: {
                value: '',
                label: ''
            }
        }

        // 清空筛选
        this.selectScreenData = {
            workingLife: '',    // 工作性质
            education: '',    // 学历要求
            salary: '',    // 薪资要求
        }
    }

    handleReportInfo = (data) => {
        // data.interviewKey
        console.log("handleReportInfo");
        this.setState({
            showRecordInfo: true,
            interviewKey: data.interviewKey,
            reportData: data
        })
    }

    closeReportInfo = () => {
        this.setState({
            showRecordInfo: false
        })
    }

    // 列表接口 点击回调
    handleDataFromChild = (data,e,mobile) => {
        this.btnClickVoice()
        this.stopBroadcast();
        this.closeAudio()
        this.chat_id = "";

        let num=null
        let tel=this.state.mobile
        if(e){
            num=e
            tel=mobile
        }
        this.setState({
            listShow: false,
            poisitinInfoData: data,
            showApplyWay: true,
            showList:1,
            //临时添加
            // audioStatus:true,

            modalBodyStyle:{
                height:'80vh'
            },
            backTo:num,
            positionId:data.positionId,
            positionType:data.employmentType == 3?2:1,
            mobile:tel,
            stopFlag:true,
            beginAudio:false,
            messageSend:false,
            voiceFlag:true,
            content:'',
            strTxt:'',
        },()=>{
            this.clearNewList()
            if (!this.state.backTo){
                this.aiBroadcastByKey('positionDetail')
            }
            console.log(this.state.stopFlag,this.state.beginAudio)
            // setTimeout(()=>{
            //     if (!this.state.backTo){
            //         this.aiBroadcastByKey('positionDetail')
            //     }
            // },200)
        })
    }
    //清除页面的两条对话
    clearNewList=()=>{
        this.setState({
            newList:[],
            content:''
        })
    }
    // 关闭职位详情
    closePositionInfo = () => {

        this.btnClickVoice()
        this.breakVoice()
        let num=1
        let height='80vh'
        if (this.state.backTo){
            num=4
            height='80vh'
        }
        this.setState({
            listShow: true,
            poisitinInfoData: {},
            showApplyWay:false,
            showList: num,
            modalBodyStyle:{
                height:height
            },
            positionId:'',
            positionType:'',
            newList:[],
            stopRobToBegin:true,
            beginAudio:false,
            waitAiRetrun: false,
            stopFlag:false,
            audioFlag:false,
            voiceFlag:true,
        })
        // this.forceUpdate();
    }

    handUserInfoBack = (data,mobile) => {
        this.breakVoice()
        console.log(data, '子组件传参');
        let height='80vh'
        let showRecord=false
        if(data==4){
            showRecord=true
        }
        this.setState({
            showList: data,
            showApplyWay: true,
            modalBodyStyle:{
                height:height
            },
            mobile:mobile,
            showRecord
        })

        this.props.setAudioAction("stopBroadcast")
        this.refreshState();
        // this.forceUpdate();
    }

    aiThinking(){
        //更改底部语音区域状态, 改为思考中
        this.setState({
            userSpeaking: false,
            audioStatus: 2
        })
    }

    //机器人播报的时候，出现可被打断的状态
    canStopAudio = () => {
        this.setState({
            audioStatus: 0
        })

    }
    
    addBtn(e){
        this.setState({
            content:e
        })
        this.add(e)
    }
    //判断历史记录没有
    getNoList(e){
        console.log(e,'p')
        //1没有，2有
        if(e===1){
            this.setState({
                btnWork:true
            })
        }else{
            this.setState({
                btnWork:false
            })
        }
    }
    getMachine=()=>{

        var mainUrl = localStorage.getItem("MainUrl");
        var data = {
            "mainUrl":mainUrl
        }

        console.log(mainUrl);

        if (mainUrl){
            /* global my */
            // if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            //     my.postMessage({type:"toOtherLink",data:mainUrl});
            // }

            this.setState({
                mainDialogVisible:true,
                logoShow:false
            })

        }else{
            let num=this.state.machineNum
            num++
            if (num==5){
                alert(this.props.sn)
                num=0
            }
            this.setState({
                machineNum:num
            })
        }
    }
    addAsk=(e)=>{
        this.stopBroadcast();
        window.tempPlayStatus = 0
        window.PlayStats = {
            playCode: "",
            status: 0, // 0 ：未播报， 1：已开始播报
            isLast: false //是否最后一个播报
        }
        this.closeAudio()
        this.setState({
            content:'',
            strTxt:'',
            audioStatus: 0,
            userSpeaking: false,
            chatRepId: ''
        },()=>{
            this.props.clearChatRepId();
            this.add(e)
        })
    }

    toAiView = (positionId,employmentType,projectId,resumeId) =>{
        console.log("-----------------toAiView-----------")
        console.log(projectId);
        console.log(resumeId);

        this.setState({
            showVoice: false
        })

        this.hideCom();
        this.props.callParentMethod(positionId,employmentType,projectId,resumeId);
        // this.moveCenter();
    }
    render() {
        return (
            <div id='cioceId' style={{ display: this.state.showVoice ? 'block' : 'none' }}>
                <div className={voiceStyle.level}>
                    {
                        this.state.logoShow?(
                            <div className={voiceStyle.logo} onClick={()=>this.getMachine()}>
                                {
                                    this.state.logo?(
                                        <img src={this.state.logo} alt=""/>
                                    ):(
                                        <img src={logoImg} alt=""/>
                                    )
                                }
                                {
                                    this.state.showList === 0?(
                                        this.state.logoTitle?(
                                            <p>{this.state.logoTitle}</p>
                                        ):(
                                            <p>数智员工小顾</p>
                                        )
                                    ):('')
                                }
                            </div>
                        ):('')
                    }
                    {
                        //)&&this.state.backTime<=30
                        this.state.backTime<=30&&this.state.backTime> 0 ?(
                            <div className={voiceStyle.back} onClick={()=>this.backHome()}>
                                <button className={this.state.backTime<=15?voiceStyle.redBg:''}>
                                    {/*<img src={homeImg} alt=""/>*/}
                                    { this.state.backTime> 0 ? (<p>退出 {this.state.backTime}s</p>) : ""}
                                </button>
                            </div>
                        ):('')
                    }
                    {/*倒计时60s停止语音*/}
                    {
                        !(this.state.dialogVisible || this.state.btnShow)&&this.state.clickXBack&& this.state.countToStopVoice<=30 && !this.state.mainDialogVisible?(
                            <div className={voiceStyle.back} onClick={()=>this.backHome()}>
                                <button className={this.state.countToStopVoice<=15?voiceStyle.redBg:''}>
                                    { this.state.countToStopVoice> 0 ? (<p>退出 {this.state.countToStopVoice}s</p>) : ""}
                                </button>
                            </div>
                        ):('')
                    }
                    {
                        this.state.dialogVisible?(
                            <div className={voiceStyle.newest} ref={this.newest}>
                                {/*{*/}
                                {/*    this.state.newList.length>1?(*/}
                                {/*        <aside className={voiceStyle.userCon}>*/}
                                {/*            <div className={voiceStyle.chatNewCon}>*/}
                                {/*                /!*<span>{this.state.content}{this.state.strTxt}</span>*!/*/}
                                {/*                <span>{this.state.newList[0].content}</span>*/}
                                {/*            </div>*/}
                                {/*        </aside>*/}
                                {/*    ):('')*/}
                                {/*}*/}
                                {
                                    this.state.newList.length>0?(
                                        <aside className={voiceStyle.robotCon}>
                                            {
                                                this.state.newList[0].content === ""?(
                                                    <div className={voiceStyle.chatNewCon}>
                                                        <SpeakLoading/>
                                                    </div>
                                                ):(
                                                    <div className={voiceStyle.chatNewCon}>
                                                <span ref={this.robotCon}>
                                                    {this.state.newList[0].content}
                                                    {/*<Markdown mdText={this.state.newList[1].content}></Markdown>*/}
                                                </span>
                                                    </div>
                                                )
                                            }
                                        </aside>
                                    ):('')
                                }
                            </div>
                        ):('')
                    }

                    {
                        <div className={voiceStyle.chatList} ref={this.chatListH}>
                            {
                                !this.state.dialogVisible?(
                                    this.state.chatList.map((el,i)=>{
                                        return(
                                            <div key={i}>
                                                {
                                                    el.user === 'user'?(
                                                        <aside className={voiceStyle.userCon}>
                                                            <div className={voiceStyle.chatListCon}>
                                                                {/*<span>{this.state.content}{this.state.strTxt}</span>*/}
                                                                <span>{el.content}</span>
                                                            </div>
                                                        </aside>
                                                    ):(
                                                        <aside className={voiceStyle.robotCon}>
                                                            {
                                                                el.content === ""?(
                                                                    <div className={voiceStyle.chatListCon}>
                                                                        <SpeakLoading/>
                                                                    </div>
                                                                ):(
                                                                    <div className={voiceStyle.chatListCon}>
                                                                        <span>{el.content}</span>
                                                                        {
                                                                            el.params?(
                                                                                <button onClick={()=>this.openShow(1,el.params)}>查看</button>
                                                                            ):('')
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </aside>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                ):('')
                            }
                            <div ref={this.chatListRef}></div>

                        </div>
                    }
                    {
                        this.state.downImgFlag&&!this.state.dialogVisible?(
                            <img src={downImg} className={voiceStyle.downImg} alt="" onClick={()=>this.scrollChatToBottom()}/>
                        ):('')
                    }
                    {
                        !this.state.dialogVisible?(

                            !this.state.psyConKey || this.state.psyConKey === ''?(
                                    <div className={voiceStyle.checkBtn}>
                                        <div className={`${voiceStyle.btnItem} ${voiceStyle.btnItemW} ${voiceStyle.btnOne}`} onClick={()=>this.toExample(1)}>
                                            <button>
                                                {/*<img src={bagImg} alt="" />*/}
                                                <span>找工作</span>
                                                <p>快捷精准推荐</p>
                                            </button>
                                        </div>
                                        <div className={`${voiceStyle.btnItem} ${voiceStyle.btnItemW} ${voiceStyle.btnTwo}`} onClick={()=>this.toExample(2)}>
                                            <button>
                                                {/*<img src={policyImg} alt="" />*/}
                                                <span>问政策</span>
                                                <p>求职政策咨询</p>
                                            </button>
                                        </div>
                                        <div className={`${voiceStyle.btnItem} ${voiceStyle.btnItemW} ${voiceStyle.btnThree}`}>
                                            <button onClick={()=>this.openShow(4)}>
                                                {/*<img src={recordImg} alt="" />*/}
                                                <span>查记录</span>
                                                <p>跟踪求职进度</p>
                                            </button>
                                        </div>
                                    </div>
                            ):(
                                <div className={voiceStyle.checkBtn}>
                                    <div className={`${voiceStyle.btnItem} ${voiceStyle.btnItemW2} ${voiceStyle.btnOne}`} onClick={()=>this.toExample(1)}>
                                        <button>
                                            {/*<img src={bagImg} alt="" />*/}
                                            <span>岗位推荐</span>
                                            <p>快捷精准推荐</p>
                                        </button>
                                    </div>
                                    <div className={`${voiceStyle.btnItem} ${voiceStyle.btnItemW2} ${voiceStyle.btnTwo}`} onClick={()=>this.toExample(2)}>
                                        <button>
                                            {/*<img src={policyImg} alt="" />*/}
                                            <span>政策咨询</span>
                                            <p>求职政策咨询</p>
                                        </button>
                                    </div>
                                    <div className={`${voiceStyle.btnItem} ${voiceStyle.btnItemW2} ${voiceStyle.btnThree}`}>
                                        <button onClick={()=>this.openShow(4)}>
                                            {/*<img src={recordImg} alt="" />*/}
                                            <span>求职记录</span>
                                            <p>跟踪求职进度</p>
                                        </button>
                                    </div>

                                    <div className={`${voiceStyle.btnItem} ${voiceStyle.btnItemW2} ${voiceStyle.btnThree}`}>
                                        <button onClick={()=>this.toExample(3)}>
                                            {/*<img src={recordImg} alt="" />*/}
                                            <span>心理关爱</span>
                                            <p>儿童心理关爱</p>
                                        </button>
                                    </div>
                                </div>
                                )



                        ):('')
                    }
                    {
                        //底部按钮  &&this.state.backTo==null
                        this.state.logoShow?(
                            <div className={voiceStyle.bottom_wrap}>
                                {
                                    this.state.showList<4?(
                                        <div className={this.state.showApplyWay||this.state.showList==1 ? `${voiceStyle.btn} ${voiceStyle.btnBg}`:(`${voiceStyle.btn}`)}>
                                            <div className={voiceStyle.btnLeft}>
                                                {
                                                    this.state.audioStatus === 0&&!this.state.showRecord? (
                                                        <aside onClick={()=>this.addVoice()}>
                                                            <img src={voiceImg} alt=""/>
                                                        </aside>
                                                    ) : ('')
                                                }
                                                {
                                                    this.state.audioStatus === 1 ? (
                                                        <article onClick={()=>this.restoreAudio()}>
                                                            {/*<div className={voiceStyle.loadingWrapper}>*/}
                                                            {/*    <div className={voiceStyle.loadingBar}*/}
                                                            {/*         style={{'--animation-duration': this.state.animationDuration}}></div>*/}
                                                            {/*</div>*/}
                                                            {
                                                                !this.state.userSpeaking?(
                                                                    <img src={this.state.voiceIng} alt=""/>
                                                                ):(
                                                                    <img src={this.state.listen} alt=""/>
                                                                )
                                                            }
                                                        </article>
                                                    ) : ('')
                                                }
                                                {
                                                    this.state.audioStatus === 3 ? (
                                                        <div className={voiceStyle.stopTalk} onClick={()=>this.peopleStopTalk()}>
                                                            <img src={stopVoice} alt=""/>
                                                        </div>
                                                    ) : ('')
                                                }
                                                {
                                                    // 发送完数据在加载回答
                                                    this.state.audioStatus === 2? (
                                                        <div className={voiceStyle.stopTalk} onClick={()=>this.peopleStopTalk()}>
                                                            <img src={this.state.stopVoice} alt=""/>
                                                        </div>
                                                    ) : ('')
                                                }
                                            </div>
                                            <div className={voiceStyle.btnRight}>
                                                {
                                                    // 最初状态
                                                    !this.state.showApplyWay&&this.state.audioStatus===0&&this.state.showList<4 ?(
                                                        // <p>点击说话</p>

                                                        <aside className={voiceStyle.swiperList}>
                                                            <Carousel dotPosition="left" autoplay="true" dots={false}>
                                                                <div className={voiceStyle.swiper}>
                                                                    <p className={voiceStyle.tospeak}>点击开始咨询</p>
                                                                </div>
                                                                {
                                                                    this.state.exampleList.map((item, index) =>
                                                                        <div key={index} className={voiceStyle.swiper}>
                                                                            <span className={voiceStyle.example}>您可以这样说：</span>
                                                                            <p className={voiceStyle.str}>{item}</p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </Carousel>
                                                        </aside>
                                                    ):('')
                                                }
                                                {
                                                    // 已经出发录音 未说话
                                                    !this.state.showApplyWay&&this.state.audioStatus===1&&!this.state.userSpeaking&&this.state.showList<4 ?(
                                                        <p>我正在听，有任何需求您可以直接说…</p>
                                                    ):('')
                                                }
                                                {
                                                    // 数字人有回复 可打断
                                                    !this.state.showApplyWay&&this.state.audioStatus === 3&&this.state.showList<4 ?(
                                                        <p>我正在说话，点击可打断我</p>
                                                    ):('')
                                                }
                                                {
                                                    // 用户语音已发送，数字人未回复
                                                    !this.state.showApplyWay&&this.state.audioStatus === 2&&this.state.showList<4 ?(
                                                        <p>我正在思考，点击可终止...</p>
                                                    ):('')
                                                }
                                                {
                                                    // 正在说话 显示内容
                                                    !this.state.showApplyWay&&this.state.audioStatus===1&&this.state.userSpeaking&&this.state.showList<4 ?(
                                                        <section>
                                                            {this.state.content}{this.state.strTxt}
                                                        </section>
                                                    ):('')
                                                }
                                                {
                                                    // 投递记录界面 展示
                                                    !this.state.showApplyWay&&this.state.showList===4&&this.state.btnWork?(
                                                       <button onClick={()=>this.toExample(1)} className={voiceStyle.getWork}>找工作</button>
                                                    ):('')
                                                }
                                                {
                                                    // 职位详情界面
                                                    this.state.showApplyWay&&this.state.backTo!==1?(
                                                        <div className={voiceStyle.signWay}>
                                                            {
                                                                // 最初状态
                                                                this.state.audioStatus===0&&this.state.showList<4 ?(
                                                                    <aside className={voiceStyle.txt}>
                                                                        <p className={voiceStyle.tospeak1}>点击开始咨询</p>
                                                                    </aside>
                                                                ):('')
                                                            }
                                                           
                                                            {
                                                                this.state.audioStatus===1&&this.state.userSpeaking&&this.state.showList<4 ?(
                                                                    <aside className={voiceStyle.txt}>
                                                                        <p className={voiceStyle.tospeak1}>
                                                                            {this.state.content}{this.state.strTxt}
                                                                        </p>
                                                                    </aside>
                                                                ):('')
                                                            }
                                                            {
                                                                !(this.state.audioStatus===0&&this.state.showList<4)&&
                                                                !(this.state.audioStatus===1&&this.state.userSpeaking&&this.state.showList<4)?(
                                                                    <aside className={voiceStyle.txt}>
                                                                        <p className={voiceStyle.tospeak1}></p>
                                                                    </aside>
                                                                ):('')
                                                            }

                                                            <aside>
                                                                <button className={voiceStyle.Alipay} onClick={this.checkFaceAndGetUserInfo}>
                                                                    <img src={Alipay} alt="" />
                                                                    刷脸报名
                                                                </button>
                                                            </aside>
                                                            <aside>
                                                                <button className={voiceStyle.identity} onClick={this.checkIdCardInfo}>
                                                                    <img src={identity} alt="" />
                                                                    身份证报名
                                                                </button>
                                                            </aside>
                                                            {/* <aside >
                                                                <button>手动报名</button>
                                                            </aside> */}
                                                        </div>
                                                    ):('')
                                                }
                                            </div>
                                        </div>
                                    ):('')
                                }
                            </div>
                        ):('')
                    }
                    {
                        !this.state.dialogVisible ? (
                            <>
                                {/*<div style={{position:'fixed',width:'80%',bottom:'25%',left:'5%',zIndex:"1000"}}>*/}
                                {/*    <button className={voiceStyle.btnBgColor} onClick={()=>this.addBtn('作为新就业群体，我应该如何购买社保')}>作为新就业群体，我应该如何购买社保</button>*/}
                                {/*    <button className={voiceStyle.btnBgColor} onClick={()=>this.addBtn('成都今天天气怎么样')}>成都今天天气怎么样</button>*/}
                                {/*    <button className={voiceStyle.btnBgColor} onClick={()=>this.openShow(5)}>查看流程</button>*/}
                                {/*</div>*/}
                                {/*<div style={{paddingTop:"20%",width:'80%',marginLeft:'5%',zIndex:"1000"}}>*/}
                                {/*    <input style={{height:"40px",width:'200px'}} placeholder="请输入文本" value={this.state.content}  onChange={(event) => this.setState({ content: event.target.value })}/>*/}
                                {/*    <button className={voiceStyle.btnBgColor} onClick={()=>this.add(this.state.content)}>add</button>*/}
                                {/*</div>*/}
                                {/*<div style={{paddingTop:"20%",width:'80%',marginLeft:'5%',zIndex:"1000"}}>*/}
                                {/*    <input style={{height:"40px",width:'200px'}} placeholder="请输入文本" value={this.state.mobile}  onChange={(event) => this.setState({ mobile: event.target.value })}/>*/}
                                {/*    <button className={voiceStyle.btnBgColor} onClick={()=>this.sendcode(this.state.mobile)}>add</button>*/}
                                {/*</div>*/}
                            </>
                        ) : (
                            <>
                                 {/*<div style={{position:'fixed',top:"10%",width:'80%',left:'5%',zIndex:"3001"}}>*/}
                                 {/*    <button className={voiceStyle.btnBgColor} onClick={()=>this.addBtn('作为新就业群体，我应该如何购买社保')}>作为新就业群体，我应该如何购买社保</button>*/}
                                 {/*    /!*<button style={{display: 'block'}} className={voiceStyle.btnBgColor} onClick={()=>this.addBtn('介绍一下这个公司')}>介绍一下这个公司</button>*!/*/}
                                 {/*    /!*<button className={voiceStyle.btnBgColor} onClick={()=>this.addBtn('介绍一下这个职位')}>介绍一下这个职位</button>*!/*/}
                                 {/*</div>*/}

                                {/*<div style={{paddingTop:"20%",width:'80%',marginLeft:'5%',zIndex:"1000"}}>*/}
                                {/*    <input style={{height:"40px",width:'200px'}} placeholder="请输入文本" value={this.state.content}  onChange={(event) => this.setState({ content: event.target.value })}/>*/}
                                {/*    <button className={voiceStyle.btnBgColor} onClick={()=>this.add(this.state.content)}>add</button>*/}
                                {/*</div>*/}
                            </>
                        )
                    }
                </div>
                
                {/*弹窗 */}
                {
                    this.state.dialogVisible ? (
                        <Modal
                            open={this.state.dialogVisible}
                            mask={false}
                            maskClosable={false}
                            keyboard={false}
                            width='100vw'
                            zIndex={10}
                            centered={false}
                            destroyOnClose={true}
                            getContainer={()=>document.getElementById('cioceId')}
                            onCancel={this.closePop}
                            style={{ backgroundColor: 'rgba(255, 255, 255, .5)',paddingBottom: 0, borderRadius: '20px', height: this.state.modalBodyStyle.height,top: '15vh'}}
                            title={null}
                            footer={null}
                            wrapClassName={this.state.modalBodyStyle.height=='80vh'?'weipay overHeight':'weipay'}
                        >
                            {this.state.scanPop ? (
                                <div>
                                    <div className={voiceStyle.goBack} onClick={this.closePop}>
                                        <i className="iconfont icon-xiangzuo"></i>
                                        <p></p>
                                        {/*<p>返回职位列表</p>*/}
                                    </div>
                                    <ScanIdCard scanStatus={this.state.scanStatus} onAgain={this.checkIdCardInfo}></ScanIdCard>
                                </div>
                            ):(
                                this.state.showList<4?(
                                        this.state.listShow && this.state.dialogVisible ? (
                                            <PositionList
                                                toWork={this.state.toWork}
                                                indCode={this.state.indCode}
                                                chatObj={this.state.chatObj}
                                                onData={this.handleDataFromChild}
                                                refreshCount={this.state.refreshCount}
                                                onArea={this.handleArea}
                                                onScreen={this.handleScreen}
                                                selectScreenData={this.selectScreenData}
                                                areaData={this.areaData}
                                                onCleanScreenAll={this.handleCleanScreenAll}
                                            >
                                            </PositionList>

                                        ) : (this.state.poisitinInfoData.employmentType == 3 ? (
                                            // 企业直招 职位详情
                                                <div>
                                                    <div className={voiceStyle.goBack} onClick={this.closePositionInfo}>
                                                        <i className="iconfont icon-xiangzuo"></i>
                                                        <p></p>
                                                        {/*<p>返回职位列表</p>*/}
                                                    </div>
                                                    {/*<TestCon/>*/}

                                                    <DirectAdmission ref={this.InterviewReport} poisitinInfoData={this.state.poisitinInfoData} toAsk={this.addAsk}></DirectAdmission>
                                                </div>
                                            ) : (
                                                // 外包派遣 职位详情
                                                <div>
                                                    <div className={voiceStyle.goBack} onClick={this.closePositionInfo}>
                                                        <i className="iconfont icon-xiangzuo"></i>
                                                        <p></p>
                                                        {/*<p>返回职位列表</p>*/}
                                                    </div>
                                                    <Outsourcing poisitinInfoData={this.state.poisitinInfoData} ref={this.Outsourcing} toAsk={this.addAsk}></Outsourcing>
                                                </div>
                                            )
                                        )
                                    ):(
                                        this.state.showList==4 && this.state.dialogVisible ?(
                                                !this.state.showRecordInfo?(
                                                    <DeliveryRecord ref={this.deliveryRecordRef} mobile={this.state.mobile} indCode={this.state.indCode} onData={this.handleReportInfo} onChildData={(e)=>this.getNoList(e)} callParentMethod={this.toAiView}></DeliveryRecord>
                                                ):(
                                                    <div>
                                                        <div className={voiceStyle.goBack} onClick={this.closeReportInfo}>
                                                            <i className="iconfont icon-xiangzuo"></i>
                                                            <p></p>
                                                            {/*<p>返回职位列表</p>*/}
                                                        </div>
                                                        <InterviewReport reportData={this.state.reportData}></InterviewReport>
                                                    </div>
                                                    )
                                        ):(
                                        this.state.showList == 6 ? (
                                            <div>
                                                <div className={voiceStyle.goBack} onClick={this.closePositionInfo}>
                                                    <i className="iconfont icon-xiangzuo"></i>
                                                    <p></p>
                                                    {/* <div></div> */}
                                                    {/*<p>返回职位列表</p>*/}
                                                </div>
                                                <Area ref={this.Area}  screenAreaParams={this.screenAreaParams} areaData={this.areaData} onData={this.handleSelectArea}></Area>
                                            </div>
                                        ) : (
                                            this.state.showList == 7 ? (
                                                <div>
                                                    <div className={voiceStyle.goBack} onClick={this.closePositionInfo}>
                                                        <i className="iconfont icon-xiangzuo"></i>
                                                        <p></p>
                                                        {/* <div></div> */}
                                                        {/*<p>返回职位列表</p>*/}
                                                    </div>
                                                    <Screen ref={this.Screen} typeList={this.typeList} selectScreenData={this.selectScreenData} onData={this.handleSelectScreen}></Screen>
                                                </div>
                                            ) : (
                                                // this.state.showList == 8?(
                                                //     <OtherLink></OtherLink>
                                                // ):(
                                                    <SignPop
                                                        num={this.state.num}
                                                        sn={this.state.sn}
                                                        poisitinInfoData={this.state.poisitinInfoData}
                                                        idCardInfoData={this.state.idCardInfoData}
                                                        userInfoData={this.state.userInfoData}
                                                        onUserInfoBack={this.handUserInfoBack}
                                                        onStop={this.stopBroadcast}
                                                    ></SignPop>
                                                // )

                                            )
                                        )
                                    )

                                )
                            )}
                            
                        </Modal>
                    ) : ('')
                }

                {/*客戶門戶弹窗 */}
                {
                    this.state.mainDialogVisible ? (
                        <Modal
                            open={this.state.mainDialogVisible}
                            mask={false}
                            maskClosable={false}
                            closable={false}
                            keyboard={false}
                            width='100vw'
                            zIndex={10001}
                            centered={false}
                            destroyOnClose={true}
                            getContainer={()=>document.getElementById('cioceId')}
                            onCancel={this.closePop}
                            style={{ backgroundColor: 'rgba(255, 255, 255, .5)',paddingBottom: 0,  height: '100vh',top: '0px',position:'fixed'}}
                            title={null}
                            footer={null}
                        >
                            <iframe style={{width: '100%', height: '100%', border: 'none'}} src={this.state.mainUrl}></iframe>
                        </Modal>
                    ): ('')
                }

                {/* /!*loading *!/   tip="数字员工加载中" */}
                {
                   this.state.loading ? (
                       <div className={voiceStyle.loadingWrap} >
                           {/* <Spin size="large"/> */}
                           <PageLoading />
                       </div>
                   ) : ('')
                }
                
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        timerType: state.timerType,
        broadKey: state.broadKey,
        audioAction: state.audioAction,
        chatRepId: state.chatRepId,
        chatQueue: state.chatQueue,
    }
};

const mapDispatchToProps = { // 将 action 映射到 props
    setTimerType,
    setTime,
    clearBroadKey,
    setAudioAction,
    setChatRepId,
    setStopChatRepId,
    clearChatRepId
};

export default connect(mapStateToProps,mapDispatchToProps,null,{ forwardRef: true } )(Vioce);