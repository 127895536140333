import logo from './logo.svg';
import { useState,useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN'; // 引入中文语言包\

import {Home} from "./pages/Home";
import {HomeNew} from "./pages/HomeNew";
import {SmallHome} from "./pages/SmallHome";
import SmallHomeV2 from "./pages/SmallHomeV2";
import AI from './pages/ai'
import OtherLink from "./pages/OtherLink/OtherLink";
function App() {

  return (
      <ConfigProvider locale={zhCN}>
          <Router>
              <Routes>
                  <Route path="/home" element={<HomeNew />} />
                  <Route path="/homeNew" element={<HomeNew />} />
                  <Route path="/smallHome" element={<SmallHome />} />
                  <Route path="/smallHome2" element={<SmallHomeV2 />} />
                  <Route path="/AI" element={<AI />} />
                  <Route path="/OtherLink" element={<OtherLink />} />
              </Routes>
          </Router>
      </ConfigProvider>
  );
}

export default App;
