import homeStyle from './smallHome.module.less'
import {useEffect, useState,useRef} from "react";
import SpeakLoading from "../components/speakLoading/SpeakLoading";
import { v4 as uuidv4 } from 'uuid';
import audioIcon from "../assets/img/smallProgram/icon.png"
import audioIcon1 from "../assets/img/smallProgram/icon1.png"
import {AudioApi} from '../services/audioApi.js';
import {useLocation} from "react-router-dom";
import bag from '../assets/img/smallProgram/bag.png'
import policy from '../assets/img/smallProgram/policy.png'
import record from '../assets/img/smallProgram/record.png'
import resumeImg from '../assets/smallProgram/resumeImg.png'
import Recorder from 'js-audio-recorder';
import {ChatApi} from "../services/chatApi.js";
const audioApi = new AudioApi();
const chatApi = new ChatApi();

export function SmallHome() {
    let webGlId = new URLSearchParams(useLocation().search).get('webGlId');

    const [chatList, setChatList] = useState([]);
    const [content, setContent] = useState('');
    const [strIndex, setStrIndex] = useState(null);
    const [strTxt, setStrTxt] = useState('');
    const [chooseChatShow, setChooseChatShow] = useState(true);
    const [audioFlag, setAudioFlag] = useState(false);
    const [stopFlag, setStopFlag] = useState(false);
    const [voiceFlag, setVoiceFlag] = useState(false);
    const [messageSend, setMessageSend] = useState(false);
    const [showAnyWay, setShowAnyWay] = useState(false);
    const [showSpeakExample, setShowSpeakExample] = useState(false);
    const [beginReturn, setBeginReturn] = useState(false);
    const [chat_id, setChatId] = useState('');
    // const [ws, setWs] = useState(null);

    const [sizeVoice, setSizeVoice] = useState(null);
    const [animationDuration, setAnimationDuration] = useState('1s');

    const chatConRef = useRef(null);
    const btnRef = useRef(null);
    const chooseChatRef = useRef(null);

    let overTime = null;
    let recodTimer = null;
    let timeJudge = null;
    let judgeNum = 0;

    const [recording, setRecording] = useState(null);
    let audioContext = null
    let mediaRecorder  = null;
    let chunks = []
    const constraints = {
        audio: {
            sampleRate: 16000, // 设置采样率为 48kHz
        }
    };
    let ac = null;

    const [recorder, setRecorder] = useState(null);
    const [dummyState, setDummyState] = useState(0); // 用于强制更新组件的状态
    const [pcmData, setPcmData] = useState([]);
    const [isRecording, setIsRecording] = useState(false);

    let ws = null;
    let websocketTimer = null;
    // let recorder = null;

    let chat_index = 0;
    let chat_temp_index = 0;
    let chat_text_temp_index = 0;
    let chatRepId = "";  //用于做打断标记 ，stop就是打断
    let timer = null;

    let queue = []


    useEffect(() => {

        //监听小程序回传的语音
        window.addEventListener('mAudio', handleAudio);

        connectWsExc(() => {
            setTimeout(() => {
                heartCheck()
            },2000)
        })

        // getPermission();
        showChat()
        return () => {
            if(ws){
                ws.close();
            }

            if(websocketTimer){
                clearInterval(websocketTimer)
                websocketTimer = null;
            }

            clearTimer();
        }
    },[])

    const heartCheck = () => {
        websocketTimer = setInterval(() => {
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.send("ping");
            }else{
                connectWsExc();
            }


        }, 3000);
    }

    /**
     *  获取麦克风权限
     * */
    const getPermission = (callback) => {
        Recorder.getPermission().then(
            () => {
                console.log("获取权限成功");

                if(callback){
                    callback();
                }
            },
            error => {
                console.log(`${error.name} : ${error.message}`);
            }
        );
    }

    const handleAudio = (res) => {

        if(res.detail.data){
            sendWsMessage(res.detail.data)
        }
    }

    // 请求录音授权
    const getAudioAuthorize = () => {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
    }

    let toProgram = (e) =>{
        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            my.postMessage({type:"positionSearch",e});
        }
    }

    let toRecordList = () =>{
        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            my.postMessage({type:"record"});
        }
    }


    let showExample = (e) =>{

        stopBroadcast();

        if(e===1){
            toProgram("positionSearch|position=&toWork=1")
        }else{

            let item = {
                "reqId": '',
                "content": '',
                "user": "assistants",
                "status": 1,
                'showButton':false,
                'params':''
            }

            let newChatList = chatList
            newChatList.push(item)
            setChatList(newChatList)

            chat_index = 1
            chat_temp_index = 1
            chat_text_temp_index = 1;

            var data = {
                reqId: "",
                type: 'chat',
                content: "你的数智助手小顾前来报到，请问想咨询哪方便的政策，你可以直接对我说。目前我擅长社保、医保、工伤险、创业帮扶、培训等政策内容的咨询。",
                last: true
            }

            showAiContent(data,chat_index)
        }
    }

    let scrollToBottom = () => {
        setTimeout(() => {
            try {
                if (chatConRef.current) {
                    chatConRef.current.scrollTop = chatConRef.current.scrollHeight;
                }
            } catch (e) {
                console.log(e);
            }
        },500)

    }

    let delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    let stopBroadcast = () =>{
        // clearTimer();

        queue = [];
        chatRepId = "stop";
        window.Player.current.stop();
    }

    let showAiContent = async (jsonObj, index) =>{
        chatRepId = "";
        console.log(jsonObj)
        scrollToBottom()
        setChooseChatShow(false)
        clearTimeout(overTime);
        let contentStr = "";
        let params = "";
        if(jsonObj){
            contentStr = jsonObj.content;
            params = jsonObj.params
        }else{
            contentStr = "";
        }

        if (contentStr) {
            setStopFlag(true)
            setMessageSend(false)
            setVoiceFlag(true)
            let reqId = jsonObj.reqId ? jsonObj.reqId : "";
            let lastItem = null
            let newChatList = [...chatList];
            if(newChatList[newChatList.length - 1].content){
                lastItem=newChatList[newChatList.length - 1];
            }else{
                lastItem=newChatList[newChatList.length - 2];
            }
            if (lastItem && lastItem.reqId !== '' && lastItem.reqId === reqId) {
                let textArr = contentStr.split('');
                for (let i = 0; i < textArr.length; i++) {
                    lastItem.content += textArr[i];

                    setChatList(newChatList)
                    await delay(150);
                    scrollToBottom()

                }
            } else {
                console.log(contentStr,'con')
                if (params){
                    // this.openShow(params)

                }

                let item = {
                    "reqId": reqId,
                    "content": contentStr,
                    "user": "assistants",
                    "status": 1,
                    'showButton': params ? true : false,
                    'params':params
                }

                newChatList.pop()
                newChatList.push(item)
                setChatList(newChatList)
                scrollToBottom();

                // for (let i = 0; i < textArr.length; i++) {
                //     if (i === 0) {
                //         let item = {
                //             "reqId": reqId,
                //             "content": textArr[i],
                //             "user": "assistants",
                //             "status": 1,
                //             'showButton': params ? true : false,
                //             'params':params
                //         }
                //
                //         newChatList.pop()
                //         newChatList.push(item)
                //         setChatList(newChatList)
                //         scrollToBottom();
                //
                //     } else {
                //         let lastItem = newChatList[newChatList.length - 1];
                //         lastItem.content += textArr[i];
                //         setChatList(newChatList)
                //         scrollToBottom();
                //     }
                //     await delay(150);
                // }
            }


            await chatApi.getChatData(contentStr).then(res => {

                // alert(JSON.stringify(res))

                if(chatRepId !== "stop"){
                    if(res.data && res.data.voiceBroadcastResult){
                        let voiceData = window.Lingjing.Tools.storyFormat(res.data.voiceBroadcastResult)

                        let data = {
                            index: index,
                            voiceData: voiceData,
                            last: jsonObj.last,
                            chatObj: jsonObj,
                            rep_id: jsonObj.reqId,
                            openAudio: true
                        }
                        queue.push(data)
                        // this.props.addChatQueue(data)

                        createTimer();
                    }else{
                        // alert("未")
                    }
                }
            })

        }
    }

    const  createTimer = () => {
        if(timer == null){
            timer = setInterval(() => {
                execQueue();
            },100)
        }
    }

    const clearTimer = () => {
        clearInterval(timer);
        timer = null;
    }

    const execQueue = () => {
        if(queue && queue.length > 0){

            let data = findAndCopy(queue, chat_temp_index)
            // console.log("------------------execQueue------------------------");
            // console.log(data);
            if(data !== null) {

                if (!window.Player.current.playing && window.tempPlayStatus === 0) {
                    if (data.voiceData) {
                        window.tempPlayStatus = 1;
                        window.Player.current.setData(data.voiceData);
                    }
                    chat_temp_index ++
                    if (data.last) {
                        queue = [];
                    }
                }
            }
        }
        else{

        }
    }

    const findAndCopy = (list,index) => {

        if(list && list.length > 0){
            for(var i=0 ;i < list.length;i++){
                let data = list[i];

                if(data.index === index){
                    return copy(data);
                }
            }
        }

        return null;
    }

    const copy = (data) => {
        let copystr = JSON.stringify(data);
        let copy = JSON.parse(copystr);
        return copy;
    }

    let startToRecord = () =>{

        // console.log("开始录音")
        // setVoiceFlag(true)
        // setBeginReturn(false)
        // setAudioFlag(true)
        //
        // let num=0
        // //实时
        // connectWsExc(() => {
        //     setTimeout(()=>{
        //         initAudio()
        //     },500)
        //
        //     recodTimer=setInterval(()=>{
        //         if(num===60){
        //             clearInterval(recodTimer)
        //             recStop()
        //         }else{
        //             num++
        //         }
        //     },1000)
        // })

        initAudio()
        // startRecord();
        // recStart();

    }

    //结束
    let endRecord = () =>{
        // if(audioFlag){
        //     recStop()
        // }
        console.log("录音结束");
        // recStop()
        // stopRecording();

    }

    //初始化录音
    let initAudio = () =>{
        judgeNum = 0;
        //添加新的对话
        let item = {
            "reqId": "",
            "content": '',
            "user": "user",
            "status": 0
        }
        let newChatList = chatList;
        newChatList.push(item)
        setChatList(newChatList)

        // setTimeout(()=>{
        stopBroadcast();
            startRecord()
        // },100)
    }

    let recStart = () =>{
        // console.log(mediaRecorder)
        // if (mediaRecorder) {
        //     mediaRecorder.start();
        // }


        navigator.mediaDevices.getUserMedia( constraints )
            .then(stream => {

                mediaRecorder = new MediaRecorder(stream);

                mediaRecorder.ondataavailable = (event) => {
                    console.log("ondataavailable----------------")
                    chunks.push(event.data);
                }

                // 定义 onstop 事件处理程序
                mediaRecorder.onstop = () => {
                    console.log("最终获取的音频----------------")
                    console.log(chunks)

                    // const url = URL.createObjectURL(new Blob(chunks,{ type: 'audio/wav' }));
                    // const link = document.createElement('a');
                    // link.href = url;
                    // link.download = "audio.wav";
                    // document.body.appendChild(link);
                    // link.click();
                    // document.body.removeChild(link);
                    // URL.revokeObjectURL(url);


                    const blob = new Blob(chunks, { type: 'audio/wav' });
                    blobToBase64(blob).then(base64Data => {
                        audioApi.sentence_asr(base64Data).then(res => {
                            console.log("语音转文本-------------------")
                            console.log(res)

                            if(res.data){
                                let newContent=content+res.data
                                setContent(newContent)
                                setStrTxt('')

                                if(newContent){
                                    ask(newContent)
                                }else{
                                    setBeginReturn(true)
                                    let newChatList = chatList;
                                    newChatList.pop()
                                    setChatList(newChatList)
                                }
                            }


                        })
                    })



                    chunks = [];
                };

                mediaRecorder.start();
                setRecording(true)
            })
            .catch(error => {
                console.log(error.message)
            });
    }

    let startRecord = () => {

        // console.log("startRecord------------------------------");
        // console.log(recorder);
        // if (recorder) {
        //     recorder.start()
        //     setIsRecording(true);
        // }

        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            // alert("打开小程序语音")
            my.postMessage({type:  "mAudio", act: "start"});
            setAudioFlag(true)
            setBeginReturn(true)
        }

    }
    function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result.split(',')[1];
                resolve(base64Data);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    // 将 Uint8Array 对象转换为字符串
    function uint8ArrayToString(uint8Array) {
        return String.fromCharCode.apply(null, uint8Array);
    }

// 将字符串编码为 Base64 字符串
    function stringToBase64(str) {
        return btoa(str);
    }

    //停止录音
    let recStop = () =>{
        console.log("recStop--------------")
        if(mediaRecorder){
            mediaRecorder.stop();
        }

    }

    const float32ToInt16 = (float32Array) => {
        const int16Array = new Int16Array(float32Array.length);
        for (let i = 0; i < float32Array.length; i++) {
            let s = Math.max(-1, Math.min(1, float32Array[i]));
            int16Array[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
        }
        return int16Array;
    };

    const floatToUint8 = (floatValue) => {
        const s = Math.max(-1, Math.min(1, floatValue));
        const int16 = s < 0 ? s * 0x8000 : s * 0x7FFF;
        const uint8Array = new Uint8Array(2);
        uint8Array[0] = int16 & 0xFF;
        uint8Array[1] = (int16 >> 8) & 0xFF;
        return uint8Array;
    };

    const stopRecording = () => {
        // if (recorder) {
        //     console.log(pcmData);
        //     recorder.stop();
        //
        //     const blob = recorder.getWAVBlob();
        //     console.log(blob);
        //
        //     // 将Blob转换为Base64字符串
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         const base64String = reader.result.split(',')[1]; // 获取Base64字符串
        //         console.log('Base64 String:', base64String);
        //
        //         audioApi.sentence_asr(base64String).then(res => {
        //             console.log("语音转文本-------------------")
        //             console.log(res)
        //
        //             if(res.data){
        //                 let newContent=content+res.data
        //                 setContent(newContent)
        //                 setStrTxt('')
        //
        //                 if(newContent){
        //                     ask(newContent)
        //                 }else{
        //                     setBeginReturn(true)
        //                     let newChatList = chatList;
        //                     newChatList.pop()
        //                     setChatList(newChatList)
        //                 }
        //             }
        //         })
        //     };
        //     reader.readAsDataURL(blob); // 将Blob作为数据URL读取
        //
        //
        // }


        /* global my */
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            // alert("打开小程序语音")
            my.postMessage({type:  "mAudio", act: "close"});
        }
    };

    let sendVoice = (blob) => {
        // let reader = new FileReader();
        //
        // console.log("sendVoice------------------------------");
        // reader.onload = function(event) {
        //     let arrayBuffer = event.target.result;
        //     let uint8Array = new Uint8Array(arrayBuffer);
        //     let newBuffer = uint8Array.buffer.slice(uint8Array.byteOffset, uint8Array.byteLength + uint8Array.byteOffset)
        //     let base64String = newBuffer.toString('base64');
        //     let str='{pcm}'+base64String
        //     console.log(str);
        //
        //     if (ws && ws.readyState === WebSocket.OPEN) {
        //         ws.send(str);
        //     }
        // }
        // reader.readAsArrayBuffer(blob);

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1]; // 获取Base64字符串
            let str = '{pcm}' + base64String;
            sendWsMessage(str)
        };
        reader.readAsDataURL(blob); // 将Blob作为数据URL读取
    }

    const sendWsMessage = (base64Str) => {
        console.log('Base64 String:', base64Str);
        if (ws && ws.readyState === WebSocket.OPEN) {
            console.log('send to ws');
            ws.send("{mp3}" + base64Str);
        }
    }

    let getWsUrl = () => {
        let chat_id = getChatId();
        var baseUrl ='wss://digapitest.sangucloud.com/asr/'+ chat_id;
        return baseUrl
    }

    let connectWsExc = (callback) => {
        let websocket=new WebSocket(getWsUrl())
        websocket.onopen = () => {
            console.log('open websocket');

            if(callback){
                callback();
            }
        };

        let that=this
        websocket.onmessage= (res)=> {
            // clearTimeout(overTime);
            judgeNum = 0;

            let obj=JSON.parse(res.data)
            console.log('接收到服务器返回的消息：' + res.data);
            // alert(res.data);
            if(strIndex){
                console.log('num',strIndex,obj.index);
                let str=''
                if(strIndex === obj.index){
                    str=obj.text
                    setStrTxt(str)
                }else{
                    let newContent =content+strTxt
                    setContent(newContent)
                    setStrTxt(obj.text)
                    setStrIndex(obj.index)
                }
            }else{
                setStrTxt(obj.text)
                // setContent(obj.text)
                setStrIndex(obj.index)
            }
            //edmund 临时
            // judgeStop()
            console.log(content);
        }

        websocket.onclose =()=>{
            console.log('关闭ws');
        }

        // setWs(websocket)
        ws = websocket;
        console.log(ws)
    }


    let ask = (message) => {
        // if (content !== "") {
        //     add();
        // }
        // let message = "";
        // for (let i = 0; i < chatList.length; i++) {
        //     let item = chatList[i];
        //     if (item.status === 0) {
        //         message += chatList[i].content + "\n";
        //         item.status = 1;
        //     }
        // }
        if (message !== "") {
            //       message = "{indCode="+ this.indCode +"}"+"{weglId="+ this.webGlId +"}" + message;
            // if(this.poisitinId){
            // 	message="{position_id="+ this.poisitinId +"}"+message
            // }
            console.log("ask message----------------------")
            console.log(message)
            setMessageSend(true)
            setVoiceFlag(false)
            setAudioFlag(false)
            // nextCas.ask(message);

            let userItem = {
                "reqId": "",
                "content": message,
                "user": "user",
                "status": 0
            }

            let item = {
                "reqId": '',
                "content": '',
                "user": "assistants",
                "status": 1,
                'showButton':false,
                'params':''
            }
            let newChatList = chatList
            newChatList.push(userItem)
            newChatList.push(item)
            setChatList(newChatList)

            setChooseChatShow(false)
            setContent("")
            setStrTxt("")

            console.log(chatList)
            //TODO: 这里需要调dify接口
            aiTalk(message)
        }
    }

    let getChatId = () => {

        if(!chat_id){
            let newChatId = uuidv4();
            setChatId(newChatId)

            return newChatId
        }

        return chat_id;
    }

    let aiTalk = async (message) => {

        //params = { ind_code: '', webgl_id: 0, position_id: 0, content: ''}
        let chat_id = getChatId();


        const response = await fetch('/digApi/digital_dify/chat_word_v2', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            dataType: "text/event-stream",
            body: JSON.stringify({
                "chat_id": chat_id,
                "user_id": "xiaogu",
                "ind_code": '',
                "position_id":  "",
                "position_type": "",
                "webgl_id": webGlId,
                "key": 'app-4EyI4VBSPQR1B6xYDqoL8y6U',
                "content": message
            })
        });

        chat_index = 0
        chat_temp_index = 1
        chat_text_temp_index = 1;

        if(!response.ok){
            throw new Error(`HTTP error! status: ${response.status}`);

            let data = {
                index: chat_index,
                voiceData: "",
                last: true,
                chatObj: null,
                rep_id: "",
                openAudio: true
            }
            queue.push(data)
        }

        const reader = response.body.getReader();
        let decoder = new TextDecoder();
        let result = true;

        while (result) {
            const { done, value } = await reader.read();
            // console.log("--------------done---------------:{}",done);
            result = !done;

            if(chatRepId === "stop"){
                result = false
                continue;
            }

            if(done){
                chat_index ++;
                // window.tempPlayStatus = 1
                let data = {
                    index: chat_index,
                    voiceData: "",
                    last: true,
                    chatObj: null,
                    rep_id: "",
                    openAudio: true
                }
                queue.push(data)
            }

            // try {
            let text = decoder.decode(value);
            console.log("------------dify 返回内容------------")
            console.log(text)
            if(text){ //现在如果dify直接返回空则是 无法回答， 之后应该是固定的文案

                const formattedData = text.replace(/\n/g, '');
                const dataEntries = text.trim().split('data: ').slice(1);

                for(var i=0; i < dataEntries.length; i++){
                    let entry = dataEntries[i];

                    try{
                        let json = JSON.parse(entry);
                        // console.log(json)
                        json.last = false;
                        chat_index ++;
                        await showAiContent(json,chat_index)

                    }catch (e){
                        console.log(e)
                    }
                }
            }

        }
    }


    let add = () => {
        if (content) {
            addUserAsk(content)
        }
    }

    let addUserAsk = (contentStr) => {
        // let item = {
        //     "reqId": "",
        //     "content": content,
        //     "user": "user",
        //     "status": 0
        // }
        // this.chatList.push(item)

        let newChatList = chatList

        if(newChatList.length > 0){
            newChatList[newChatList.length-1].content=content+strTxt;
            if (newChatList.length > 0) {
                setChatList(newChatList)
                scrollToBottom();
            }
        }
    }

    let showChat = () => {
        let num=btnRef.current.offsetHeight
        let h=null
        if(chooseChatRef.current){
            let chat=chooseChatRef.current.offsetHeight
            h=num-chat+10
            // h=num+10
        }else{
            h=num+10
        }
        chatConRef.current.style.maxHeight="calc(100vh - "+h+"px)"
    }

    //查看其他
    let anyMore = () =>{
        if(showAnyWay||showSpeakExample){
            //全收起来
            setShowAnyWay(false)
            setShowSpeakExample(false)
        }else{
            //展示三个按钮
            setShowAnyWay(true)

        }
        setTimeout(()=>{
            showChat()
        },300)
    }
    let toResume=()=>{
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            my.postMessage({type:"resume"});
        }
    }

    return (
        <div className={homeStyle.homeIndex}>
            <div className={homeStyle.home}>
                <div className={homeStyle.dig_con}>
                    <div className={homeStyle.textCon}>
                        <div className={homeStyle.chatCon}>
                            <div className={homeStyle.chatItem} ref={chatConRef}>
                                <div className={homeStyle.itemCon}>
                                    {
                                        chatList.map((item, index) => (

                                            item.user === 'user'? (
                                                <div key={index} className={homeStyle.userCon} >
                                                    <p className={`${homeStyle.bgBlue} ${homeStyle.robotItem}`} >
                                                        {
                                                            item.content||content||strTxt?(
                                                                <>
                                                                    {item.content ? item.content : content + strTxt}
                                                                </>
                                                            ):('')
                                                        }
                                                    </p>
                                                </div>
                                            ):(
                                                <div key={index} className={homeStyle.robotCon}>
                                                    {
                                                        item.content ? (
                                                            <div className={homeStyle.robotItem}>
                                                                {item.content}
                                                                {
                                                                    item.showButton ? (
                                                                        <button onClick={() => {
                                                                            toProgram(item.params)
                                                                        }}>查看职位</button>
                                                                    ) : ('')
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className={homeStyle.robotItem}>
                                                                <SpeakLoading></SpeakLoading>
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            )
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={homeStyle.btn} ref={btnRef}>
                            {
                                chooseChatShow ? (
                                    <div className={homeStyle.chooseChat} ref={chooseChatRef}>
                                        <p>您好，我是您的求职助手小顾，您有什么要求可以直接对我说。</p>
                                        <button className={homeStyle.itemButton} onClick={() => showExample(1)} >我想找工作</button>
                                        <button className={homeStyle.itemButton} onClick={() => showExample(2)}>查询政策</button>
                                        <button className={homeStyle.itemButton} onClick={toRecordList}>查看报名记录</button>
                                    </div>
                                ) : ('')
                            }

                            <div className={homeStyle.speakBtn}>
                                <button  onTouchStart={startToRecord} onTouchEnd={endRecord}>
                                    {
                                        audioFlag ? (
                                            <img src={audioIcon} alt="" />

                                        ):(
                                            <img src={audioIcon1} alt="" />
                                        )
                                    }
                                    {
                                        audioFlag ? ('正在录入') : ('按住说话')
                                    }
                                </button>
                                <aside onClick={anyMore}>
                                    <i className={showAnyWay || showSpeakExample?'iconfont icon-guanbi':'iconfont icon-jia'}></i>
                                </aside>
                            </div>
                            {
                                showAnyWay?(
                                    <div className={homeStyle.checkBtn} id="checkBtn">
                                        <div className={`${homeStyle.btnItem} ${homeStyle.btnOne}`} onClick={() => showExample(1)}>
                                            <img src={bag} alt="" />
                                            <span>找工作</span>
                                        </div>
                                        <div className={`${homeStyle.btnItem} ${homeStyle.btnTwo}`} onClick={() => showExample(2)}>
                                            <img src={policy} alt="" />
                                            <span>查政策</span>
                                        </div>
                                        <div className={`${homeStyle.btnItem} ${homeStyle.btnThree}`} onClick={toRecordList}>
                                            <img src={record} alt="" />
                                            <span>看记录</span>
                                        </div>
                                        <div className={`${homeStyle.btnItem} ${homeStyle.btnFour}`} onClick={toResume}>
                                            <img src={resumeImg} alt="" />
                                            <span>看简历</span>
                                        </div>
                                    </div>
                                ):('')
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}